import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import { useStyles } from '../leaderboard-scramble/scramble-leaderboard.style-hook'

const TeamLeaderboardHead = (props) => {
  const { rounds = 4, showFavorite, nested } = props
  const styles = useStyles({ showFavorite, nested })

  return (
    <Box
      display="flex"
      className={styles.headContainer}
      role="row"
    >
      <div className={`${styles.rank} ${styles.labelRank}`} role="columnheader" aria-label="position">POS</div>
      <div className={`${styles.rank} ${styles.labelRank} ${styles.hideMobile}`} role="columnheader" aria-label="position">MOV</div>
      <div className={styles.itemName}>Team</div>
      <div className={styles.labelValue} aria-label="total score" role="columnheader">
        <div aria-hidden="true">Tot<span className={styles.hideMobile}>al</span></div>
      </div>
      <div className={`${styles.labelValue} ${styles.hideMobile}`} role="columnheader" aria-label="round 1 score">R1</div>
      {rounds >= 2 && <div className={`${styles.labelValue} ${styles.hideMobile}`} role="columnheader" aria-label="round 2 score">R2</div>}
      {rounds >= 3 && <div className={`${styles.labelValue} ${styles.hideMobile}`} role="columnheader" aria-label="round 3 score">R3</div>}
      {rounds >= 4 && <div className={`${styles.labelValue} ${styles.hideMobile}`} role="columnheader" aria-label="round 4 score">R4</div>}
    </Box>
  )
}

TeamLeaderboardHead.propTypes = {
  rounds: PropTypes.number,
  showFavorite: PropTypes.bool,
  nested: PropTypes.bool,
}

TeamLeaderboardHead.defaultProps = {}

export default TeamLeaderboardHead
