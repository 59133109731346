import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

export const BrandedButton = withStyles(theme => ({
  root: {
    height: '36px',
    padding: '0 20px',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))(Button)
