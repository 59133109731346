import React from 'react'
import { SITE_PRIMARY } from '../icons'

const IconTicket = (props) => {
  const { fill = SITE_PRIMARY } = props
  return (
    <svg width={28} height={28} {...props}>
      <title>Tickets</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M14 0c7.732 0 14 6.268 14 14s-6.268 14-14 14S0 21.732 0 14 6.268 0 14 0zm2.047 6a.359.359 0 00-.238.098l-9.711 9.711c-.13.13-.13.34-.002.47l1.533 1.549a.335.335 0 00.237.098.358.358 0 00.236-.098c.57-.57 1.5-.57 2.07 0 .57.572.57 1.5 0 2.07a.332.332 0 000 .472l1.549 1.534a.334.334 0 00.47-.002l9.711-9.71a.332.332 0 00-.003-.475l-1.663-1.619c-.017-.016-.033-.021-.047-.031-.022-.016-.042-.034-.066-.043-.017-.007-.034-.006-.052-.01-.024-.006-.049-.014-.074-.014-.02 0-.04.008-.06.012-.022.004-.044.005-.064.014-.022.008-.04.025-.06.039-.016.011-.034.018-.049.032-.248.25-.58.386-.93.386l-.15-.008a1.308 1.308 0 01-.782-.378 1.308 1.308 0 01-.386-.93c0-.352.138-.683.386-.932.01-.01.015-.023.023-.034.019-.024.038-.047.05-.074.007-.02.008-.04.012-.06.004-.022.012-.043.012-.065 0-.024-.007-.048-.013-.072-.003-.018-.003-.036-.01-.053-.01-.025-.028-.046-.044-.069-.01-.013-.015-.028-.026-.04l-.003-.003-.001-.002-1.618-1.662A.334.334 0 0016.047 6zm-.059 1l1.148 1.179a1.915 1.915 0 00.204 2.48c.365.364.849.565 1.365.565l.173-.007a1.92 1.92 0 00.943-.353L21 12.01l-2.965 2.966-.56-.56a.324.324 0 10-.459.459l.56.56L12.011 21l-1.063-1.053a2.076 2.076 0 00-.213-2.683c-.73-.73-1.872-.8-2.682-.212L7 15.988l5.564-5.564.56.56a.324.324 0 10.459-.46l-.56-.559L15.988 7zm-1.419 5.098a.333.333 0 10-.471.471l1.333 1.334a.332.332 0 00.471 0 .334.334 0 000-.472z"
          fill={fill}
        />
      </g>
    </svg>
  )
}
export default IconTicket
