/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { useState } from 'react'
import FlagScorecard from './flag-scorecard'
import {
  useStyles,
} from '../scramble-leaderboard.style-hook'

export const checkTurn = (turn, thisTurn) => {
  return turn === thisTurn ? 'flex' : 'none'
}

const FlagInnerItem = (props) => {
  const {
    status,
    thru,
    points,
    playerName,
    teamState,
    holes,
    oppPlayerName,
    oppState,
    oppHoles,
  } = props

  const styles = useStyles()
  const [showScorecard, setShowScorecard] = useState(false)

  function handleToggle() {
    setShowScorecard(!showScorecard)
  }

  const addFields = (array = [], val) => array
    .map(item => parseInt(item[val], 10) || 0)
    .reduce((prev, next) => prev + next, 0)

  const front = holes && holes.slice(0, 9)
  const oppFront = oppHoles && oppHoles.slice(0, 9)

  const frontCalculated = addFields(front, 'score')
  const oppFrontCalculated = addFields(oppFront, 'score')

  const back = holes && holes.slice(9, 18)
  const oppBack = oppHoles && oppHoles.slice(9, 18)

  const backCalculated = addFields(back, 'score')
  const oppBackCalculated = addFields(oppBack, 'score')

  const totalScore = backCalculated + frontCalculated
  const oppTotalScore = oppBackCalculated + oppFrontCalculated

  let showBack9 = false
  for (let i = 0; i < back.length; i += 1) {
    if (back[i] !== '-' && back[i] !== 0 && back[i] !== null) {
      showBack9 = true
    }
  }
  const [turn, setTurn] = useState(showBack9 ? 'back' : 'front')

  function handleTurnChange(event, newValue) {
    setTurn(newValue)
  }

  return (
    <Box className={styles.teamSection}>
      <div onClick={handleToggle} className={styles.teamRow} role="row" tabIndex={0}>
        {/* team title */}
        <div className={styles.teamCellWrapper}>
          <div className={styles.teamCell} role="cell">
            <Typography className={styles.teamClause} variant="body2">
              {`${playerName} (${teamState}) `}
            </Typography>
            <span className={styles.vs}>vs</span>
            <Typography className={styles.teamClause} variant="body2">
              {` ${oppPlayerName} (${oppState}) `}
            </Typography>
          </div>
          <KeyboardArrowDownIcon className={`${styles.chevron} ${showScorecard ? 'expanded' : ''}`} />
        </div>

        {/* match status */}
        <div
          className={`${styles.itemCell} ${styles.hideMobile}`}
          role="cell"
        >
          <Typography variant="body2">
            {status}
          </Typography>
        </div>
        {/* match progress */}
        <div
          className={`${styles.itemCell} ${styles.hideMobile}`}
          role="cell"
        >
          <Typography variant="body2">
            {thru}
          </Typography>
        </div>
        {/* match points */}
        <div
          className={styles.itemCell}
          role="cell"
        >
          <Typography variant="body2">
            +{points}
          </Typography>
        </div>
      </div>
      {showScorecard && (
        <Box
          className={styles.internalScorecard}
          pt={[4, 0]}
          pb={2}
        >
          <FlagScorecard
            frontHoles={front}
            backHoles={back}
            outScore={frontCalculated}
            inScore={backCalculated}
            totalScore={totalScore}
            oppFront={oppFront}
            oppBack={oppBack}
            oppOutScore={oppFrontCalculated}
            oppInScore={oppBackCalculated}
            oppTotalScore={oppTotalScore}
            turn={turn}
            handleTurnChange={() => handleTurnChange()}
            {...props}
          />
        </Box>
      )}
    </Box>
  )
}

FlagInnerItem.propTypes = {
  status: PropTypes.string,
  thru: PropTypes.string,
  points: PropTypes.number,
  playerName: PropTypes.string,
  teamState: PropTypes.string,
  holes: PropTypes.array,
  oppPlayerName: PropTypes.string,
  oppState: PropTypes.string,
  oppHoles: PropTypes.array,
}

FlagInnerItem.defaultProps = {
}

export default FlagInnerItem
