/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { states } from '@utils/states'
import { useStyles } from '../../leaderboard-team/team-leaderboard-item.styles'
import FlagInnerWrapper from './flag-inner-wrapper'

const FlagLeaderboardItem = React.memo((props) => {
  const [showScorecard, toggleScoreCard] = useState(false)
  const styles = useStyles()
  const {
    teamPosition,
    matchCurrentFlag,
    totalPoints,
    teamName,
    flags,
    liveStreamUrl,
    opponent,
    move,
  } = props

  const moveShow = +move < 0 ? +move * -1 : +move

  const sortedFlags = [...flags].sort((a, b) => {
    if (a.flagName < b.flagName) return -1
    if (a.flagName > b.flagName) return 1
    return 0
  })

  function handleToggle() {
    toggleScoreCard(!showScorecard)
  }

  const formatNames = (inputString, opponentBool) => {
    const firstSection = inputString.split(' (')[0]
    const secondSection = inputString.split(' (')[1]
    let teamState = ''
    let playerNameString = ''
    if (!states[firstSection]) {
      teamState = opponentBool ? opponent.teamName : teamName
      playerNameString = inputString
    } else {
      teamState = firstSection
      playerNameString = secondSection.slice(0, secondSection.length - 1)
    }
    const splitPlayerName = playerNameString.split('/')
    const playerName = `${splitPlayerName[0]} / ${splitPlayerName[1]}`

    return { teamState, playerName }
  }

  const formatHoles = (holes) => {
    const holesArray = []
    for (let i = 0; i < 18; i += 1) {
      holesArray.push({ hole: i + 1, score: null })
    }
    holes.forEach((el) => {
      holesArray[Number(el.hole - 1)] = el
    })
    return holesArray
  }

  const matchesByFlag = []

  // group data by flag and matchId
  for (let i = 0; i < flags.length; i += 1) {
    let startHole = flags[i].players[0].holes[0]?.hole
    const formattedFlagMatches = []
    const [opponentFlagObj] = opponent.flags.filter(el => el.flagName === flags[i].flagName)
    flags[i].players.forEach((el) => {
      if (!startHole) {
        startHole = el.holes[0]?.hole
      }
      if (el.holes.length) {
        const [opponentMatchObj] = opponentFlagObj.players.filter(item => item.matchId === el.matchId)
        const matchObj = {
          flagName: flags[i].flagName,
          matchId: el.matchId,
          status: el.status,
          points: el.points,
          thru: el.thru,
          courseName: el.courseName,
          playerName: formatNames(el.name).playerName,
          teamState: formatNames(el.name).teamState,
          holes: formatHoles(el.holes),
          oppPlayerName: formatNames(opponentMatchObj.name).playerName,
          oppState: formatNames(opponentMatchObj.name, true).teamState,
          oppHoles: formatHoles(opponentMatchObj.holes),
          startHole: startHole || null,
        }
        formattedFlagMatches.push(matchObj)
      }
    })
    const sortedMatches = [...formattedFlagMatches].sort((a, b) => b.points - a.points)
    matchesByFlag.push(sortedMatches)
  }

  const filteredMatchesByFlag = matchesByFlag.filter(match => match.length > 0)
  const sortedMatchesByFlag = [...filteredMatchesByFlag].sort((a, b) => {
    if (a[0].flagName < b[0].flagName) return -1
    if (a[0].flagName > b[0].flagName) return 1
    return 0
  })

  return (
    <div className={styles.outer}>
      <div
        className={styles.item}
        role="row"
        onClick={handleToggle}
        tabIndex={0}
      >
        <Box
          className={styles.rank}
          pl={2}
          role="cell"
          aria-label={`position: ${teamPosition}`}
        >
          {teamPosition}
        </Box>
        <Box
          // eslint-disable-next-line no-nested-ternary
          className={`${styles.move} ${styles.hideMobile} ${!move ? styles.moveNone : move > 0 ? styles.moveUp : styles.moveDown}`}
          pl={2}
          role="cell"
          aria-label={`move: ${move}`}
        >
          {move ? <ArrowDropDownIcon className={`${styles.moveArrow} ${move > 0 && styles.moveUpArrow}`} /> : null}
          {move ? moveShow : '-'}
        </Box>
        <div className={styles.itemName}>
          <Typography className={styles.lastName} variant="h6">
            {teamName}
          </Typography>
          <KeyboardArrowDownIcon className={`${styles.chevron} ${showScorecard ? 'expanded' : ''}`} />
        </div>
        {liveStreamUrl && (
          <div className={`${styles.itemStreamUrl} ${styles.hideMobile}`}>
            <a href={liveStreamUrl} target="_blank" rel="noopener noreferrer">
              <Tooltip title="Watch Featured Groups">
                <div className={styles.liveStreamIcon} />
              </Tooltip>
            </a>
          </div>
        )}
        <div
          className={`${styles.itemValue} ${styles.hideMobile}`}
          role="cell"
          aria-label={`first flag score: ${sortedFlags ? sortedFlags[0].points : '-'}`}
        >
          {sortedFlags ? sortedFlags[0].points : '-'}
        </div>
        {matchCurrentFlag >= 2 && (
          <div
            className={`${styles.itemValue} ${styles.hideMobile}`}
            role="cell"
            aria-label={`second flag score: ${sortedFlags ? sortedFlags[1].points : '-'}`}
          >
            {sortedFlags ? sortedFlags[1].points : '-'}
          </div>
        )}
        {matchCurrentFlag >= 3 && (
          <div
            className={`${styles.itemValue} ${styles.hideMobile}`}
            role="cell"
            aria-label={`third flag score: ${sortedFlags ? sortedFlags[2].points : '-'}`}
          >
            {sortedFlags ? sortedFlags[2].points : '-'}
          </div>
        )}
        <div
          className={styles.itemValue}
          role="cell"
          aria-label={`total points: ${totalPoints || '-'}`}
        >
          {totalPoints || '-'}
        </div>
      </div>

      {showScorecard && (
        <Box
          pt={[4, 0]}
          pl={[0, 0, 4]}
          pr={[0, 0, 4]}
          pb={2}
          className={styles.inner}
        >
          <FlagInnerWrapper
            {...props}
            matches={sortedMatchesByFlag}
            handleToggle={() => handleToggle()}
          />
        </Box>
      )}
    </div>
  )
})

FlagLeaderboardItem.propTypes = {
  liveStreamUrl: PropTypes.string,
  teamPosition: PropTypes.string,
  matchCurrentFlag: PropTypes.number,
  totalPoints: PropTypes.number,
  teamName: PropTypes.string,
  flags: PropTypes.array,
  move: PropTypes.number,
  opponent: PropTypes.object,
}

FlagLeaderboardItem.defaultProps = {}

export default FlagLeaderboardItem
