import PropTypes from 'prop-types'
import {
  ColBox,
  CellBox,
  ScoreCellBox,
  Decor,
  Win,
  Start,
} from '../scramble-leaderboard.style-hook'

export const translateScore = (score, opponent) => {
  const rel = parseInt(score, 10) - parseInt(opponent, 10)
  switch (true) {
  case score === '' || score === '0' || !score:
    return <Decor>-</Decor>
  case rel < 0:
    return <Win>{score}</Win>
  default:
    return <Decor>{score}</Decor>
  }
}

const FlagScoreCell = ({
  hole,
  score,
  oppScore,
  startHole,
}) => (
  <ColBox key={hole}>
    {startHole && (startHole === hole)
      ? <CellBox><Start>{hole}</Start></CellBox>
      : <CellBox>{hole}</CellBox>
    }
    <CellBox />
    <ScoreCellBox style={{ height: 40 }}>{translateScore(score, oppScore)}</ScoreCellBox>
    <CellBox />
    <ScoreCellBox style={{ height: 40 }}>{translateScore(oppScore, score)}</ScoreCellBox>
  </ColBox>
)

FlagScoreCell.propTypes = {
  hole: PropTypes.number,
  score: PropTypes.number,
  oppScore: PropTypes.number,
  startHole: PropTypes.number,
}

export default FlagScoreCell
