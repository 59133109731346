import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import FlagScoring from './flag-scoring'
import FlagScoreCell from './flag-score-cell'
import {
  useStyles,
  ScorecardRoot,
  TurnBox,
  ColBox,
  FirstCol,
  CellBox,
  ScoreCellBox,
  FirstCell,
  NameCell,
  RoundTabs,
  HalfTab,
} from '../scramble-leaderboard.style-hook'


export const checkTurn = (turn, thisTurn) => {
  return turn === thisTurn ? 'flex' : 'none'
}

const FlagScorecard = (props) => {
  const {
    isMobile,
    turn,
    handleTurnChange,
    frontHoles,
    backHoles,
    outScore,
    inScore,
    totalScore,
    oppFront,
    oppBack,
    oppOutScore,
    oppInScore,
    oppTotalScore,
    courseName,
    playerName,
    teamState,
    oppPlayerName,
    oppState,
    startHole,
  } = props

  const styles = useStyles()

  return (
    <ScorecardRoot>

      {isMobile && (
        <RoundTabs value={turn} onChange={handleTurnChange} indicatorColor="primary" variant="fullWidth">
          <HalfTab value="front" label="Front 9" />
          <HalfTab value="back" label="Back 9" />
        </RoundTabs>
      )}

      <Box display="flex" flexDirection="column">
        <Typography variant="h6" className={styles.courseName}>{courseName}</Typography>
        <Box display="flex">
          <TurnBox
            style={{ display: isMobile ? checkTurn(turn, 'front') : 'flex' }}
          >
            <FirstCol>
              <FirstCell><div>Hole</div></FirstCell>
              <NameCell><Typography>{`${playerName} (${teamState})`}</Typography></NameCell>
              <FirstCell><div>Score</div></FirstCell>
              <NameCell><Typography>{`${oppPlayerName} (${oppState})`}</Typography></NameCell>
              <FirstCell><div>Score</div></FirstCell>
            </FirstCol>
            {frontHoles.map((cell, i) => {
              return (
                <FlagScoreCell
                  {...cell}
                  oppScore={oppFront[i].score}
                  key={`${playerName}-${cell.hole}`}
                  startHole={startHole}
                />
              )
            })}

            <ColBox>
              <CellBox>Out</CellBox>
              <CellBox />
              <CellBox>{outScore || '-'}</CellBox>
              <CellBox />
              <CellBox>{oppOutScore || '-'}</CellBox>
            </ColBox>
          </TurnBox>

          <TurnBox
            style={{ display: isMobile ? checkTurn(turn, 'back') : 'flex' }}
          >
            {isMobile && turn === 'back' && (
              <FirstCol>
                <FirstCell><div>Hole</div></FirstCell>
                <NameCell><Typography>{`${playerName} (${teamState})`}</Typography></NameCell>
                <FirstCell><div>Score</div></FirstCell>
                <NameCell><Typography>{`${oppPlayerName} (${oppState})`}</Typography></NameCell>
                <FirstCell><div>Score</div></FirstCell>
              </FirstCol>
            )}
            {backHoles.map((cell, i) => {
              return (
                <FlagScoreCell
                  {...cell}
                  oppScore={oppBack[i].score}
                  key={`${playerName}-${cell.hole}`}
                  startHole={startHole}
                />
              )
            })}
            <ColBox>
              <CellBox>In</CellBox>
              <CellBox />
              <CellBox>{inScore || '-'}</CellBox>
              <CellBox />
              <ScoreCellBox>{oppInScore || '-'}</ScoreCellBox>
            </ColBox>
            <ColBox>
              <CellBox><span className={styles.lastCell}>Tot</span></CellBox>
              <CellBox />
              <CellBox><span className={styles.lastCell} data-cy="total">{totalScore || '-'}</span></CellBox>
              <CellBox />
              <ScoreCellBox>
                <span className={styles.lastCell} data-cy="total-score">{oppTotalScore || '-'}</span>
              </ScoreCellBox>
            </ColBox>
          </TurnBox>
        </Box>
      </Box>

      <FlagScoring />

    </ScorecardRoot>
  )
}

FlagScorecard.propTypes = {
  turn: PropTypes.string,
  handleTurnChange: PropTypes.func,
  isMobile: PropTypes.bool,
  inScore: PropTypes.number,
  outScore: PropTypes.number,
  totalScore: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  frontHoles: PropTypes.array,
  backHoles: PropTypes.array,
  oppFront: PropTypes.array,
  oppBack: PropTypes.array,
  oppOutScore: PropTypes.number,
  oppInScore: PropTypes.number,
  oppTotalScore: PropTypes.number,
  courseName: PropTypes.string,
  playerName: PropTypes.string,
  teamState: PropTypes.string,
  oppPlayerName: PropTypes.string,
  oppState: PropTypes.string,
  startHole: PropTypes.number,
}

FlagScorecard.defaultProps = {
}

export default FlagScorecard
