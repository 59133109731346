/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import ErrorBoundary from '@components/error/boundary'
import FlagLeaderboardHead from './flag-leaderboard-head'
import FlagLeaderboardItem from './flag-leaderboard-item'
import LeaderboardOuterItem from '../leaderboard-outer-item'

const FlagLeaderboard = React.memo((props) => {
  const {
    enableStatus,
    liveStreamUrl,
    flagTeamLeaderboards,
    eventStage,
    currentRoundState,
  } = props

  const isTournamentComplete = currentRoundState === 'completed'
  const paddingY = [2, 2, 1]
  const sorted = flagTeamLeaderboards?.length > 0 && [...flagTeamLeaderboards].sort((a, b) => a.teamPosition - b.teamPosition)
  const matchName = flagTeamLeaderboards?.length > 0 && `${flagTeamLeaderboards[0]?.teamName} vs ${flagTeamLeaderboards[1]?.teamName} : Flags`

  const [showLeaderboard, toggleLeaderboard] = useState(false)

  function handleToggle() {
    toggleLeaderboard(!showLeaderboard)
  }

  return (
    <Box py={paddingY}>
      <Container role="table">
        <LeaderboardOuterItem
          matchName={matchName}
          isTournamentComplete={isTournamentComplete}
          eventStage={eventStage}
          winner={sorted[0]?.teamName}
          handleToggle={() => handleToggle()}
          showLeaderboard={showLeaderboard}
        />
        {showLeaderboard && sorted.length > 0 && (
          <FlagLeaderboardHead flags={sorted[0].currentFlag} nested />
        )}
        {showLeaderboard && sorted.length > 0 && sorted?.map((team, index) => (
          <ErrorBoundary key={team.teamId}>
            <FlagLeaderboardItem
              {...team}
              opponent={sorted[1 - index]}
              enableStatus={enableStatus}
              matchCurrentFlag={sorted[0].currentFlag}
              liveStreamUrl={liveStreamUrl}
            />
          </ErrorBoundary>
        ))}
      </Container>
    </Box>
  )
})

FlagLeaderboard.propTypes = {
  liveStreamUrl: PropTypes.string,
  enableStatus: PropTypes.bool,
  flagTeamLeaderboards: PropTypes.array,
  eventStage: PropTypes.string,
  currentRoundState: PropTypes.string,
}

FlagLeaderboard.defaultProps = {}

export default FlagLeaderboard
