import PropTypes from 'prop-types'
import {
  ColBox,
  CellBox,
  ScoreCellBox,
  Decor,
  Bogey,
  DoubleBogey,
  Birdey,
  Eagle,
  Albatross,
} from './team-stroke-scorecard.styles'

export const translateScore = (par, score) => {
  const rel = parseInt(score, 10) - parseInt(par, 10)
  switch (true) {
  case score === '' || score === '0' || score === 0:
    return <Decor>-</Decor>
  case rel >= 2:
    return <DoubleBogey>{score}</DoubleBogey>
  case rel === 1:
    return <Bogey>{score}</Bogey>
  case rel === 0:
    return <Decor>{score}</Decor>
  case rel === -1:
    return <Birdey>{score}</Birdey>
  case rel === -2:
    return <Eagle>{score}</Eagle>
  case rel <= -3:
    return <Albatross>{score}</Albatross>
  default:
    return null
  }
}

const ScoreCell = ({
  hole,
  par,
  score,
  yards,
  status,
  enableStatus,
}) => {
  return (
    <ColBox key={hole}>
      <CellBox>{hole || '-'}</CellBox>
      <CellBox>{yards || '-'}</CellBox>
      <CellBox>{par || '-'}</CellBox>
      <ScoreCellBox>{translateScore(par, score) || '-'}</ScoreCellBox>
      {enableStatus && <ScoreCellBox>{score ? status : '-'}</ScoreCellBox>}
    </ColBox>
  )
}

ScoreCell.propTypes = {
  hole: PropTypes.number,
  par: PropTypes.number,
  score: PropTypes.number,
  enableStatus: PropTypes.bool,
  yards: PropTypes.number,
  status: PropTypes.string,
}

export default ScoreCell
