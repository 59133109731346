import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import defaults from '@config/defaults'
import Error from '@components/error'
import ErrorBoundary from '@components/error/boundary'
import { LeaderboardProvider } from './team-leaderboard.provider'
import MyLeaderboard from './team-leaderboard.saved'
import TeamLeaderboardContainer from './team-leaderboard-container'

const QUERY = gql`
query Leaderboard($id: String!, $feedId: String!) {
  tournament(tournamentId: $id, feedId: $feedId) {
      tournamentId
      name
      startDate
      feedId
      currentRound
      currentRoundState
      rounds
      updatedAt
      strokeTeamLeaderboards {
          teamName
          teamPosition
          tournamentRelativePar
          teamId
          rounds {
              round
              relativePar
              courseName
              move
          }
              players {
                  name
                  playerId
                  rounds {
                      round
                      stroke
                      isCounted
                      relativePar
                      holes {
                          hole
                          yards
                          par
                          score
                          relativePar
                      }
                  }
              }
          } 
      }
}
`

export function TeamLeaderboardQuery(props) {
  const { leaderboardPollInterval, fullPage, eventTournamentIds } = props
  let pollInterval = process.browser ? defaults.leaderboardPollInterval : null
  pollInterval = process.browser && leaderboardPollInterval
    ? leaderboardPollInterval * 60 * 1000 : pollInterval
  const fetchPolicy = process.browser ? 'cache-and-network' : 'cache-first'

  return (
    <Query
      query={QUERY}
      variables={{ ...props, id: eventTournamentIds.team.tournamentId }}
      pollInterval={pollInterval}
      fetchPolicy={fetchPolicy}
      context={{ clientName: 'pga-datafeed' }}
    >
      {({
        error, data, loading,
      }) => {
        const response = data && data.tournament

        if (loading && !response) return <LinearProgress />
        if (error) return <Error error={error} />

        return response ? (
          <ErrorBoundary>
            <LeaderboardProvider>
              {fullPage && <MyLeaderboard {...props} {...response} />}
              <TeamLeaderboardContainer {...props} {...response} />
            </LeaderboardProvider>
          </ErrorBoundary>
        ) : <div />
      }}
    </Query>
  )
}

TeamLeaderboardQuery.propTypes = {
  fullPage: PropTypes.bool,
  leaderboardPollInterval: PropTypes.number,
  eventTournamentIds: PropTypes.object,
}
