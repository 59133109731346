import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Close from '@material-ui/icons/Close'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { CloseButton } from '@components/leaderboard/leaderboard.styles'
import {
  useStyles,
  RoundTab,
  RoundTabs,
} from '@components/leaderboard-scramble/scramble-leaderboard.style-hook'
import TeamStrokeInnerItem from './team-stroke-inner-item'

export const addFields = (array = [], val) => array
  .map(item => (parseInt(item[val], 10) || 0))
  .reduce((prev, next) => prev + next, 0)

/**
 * Given the number of available rounds, and the current round (which is one-indexed),
 * return the index of the (zero-indexed) rounds array to show.
 */
export function getRoundToShow(currentRound, numRounds) {
  let roundToShow = currentRound - 1
  // don't go out of bounds of the rounds array
  if (roundToShow >= numRounds) roundToShow = Math.max(0, numRounds - 1)
  return roundToShow
}

const TeamStrokeInnerWrapper = (props) => {
  const {
    currentRound,
    rounds,
    handleToggle,
    name,
    players,
    enableStatus,
  } = props

  // FIXME: If there are no players, we can't display a scorecard
  if (!players) return <div>No Scorecard Available</div>

  const [round, setRound] = useState(getRoundToShow(
    currentRound > 4 ? 4 : currentRound, rounds.length,
  ))

  function getIncluded(selectedRound) {
    const includedRounds = []
    players.forEach((player) => {
      const playerObj = {
        name: player.name,
        playerId: player.playerId,
      }
      player.rounds.forEach((el) => {
        if (el.round - 1 === selectedRound && el.isCounted) {
          includedRounds.push({ ...playerObj, round: el })
        }
      })
    })
    let res = []
    if (includedRounds[0]?.round.relativePar) {
      res = includedRounds.sort((a, b) => Number(a.round.relativePar) - Number(b.round.relativePar))
    } else {
      res = includedRounds.sort((a, b) => Number(a.round.stroke) - Number(b.round.stroke))
    }
    return res
  }

  function getExcluded(selectedRound) {
    const excludedRounds = []
    players.forEach((player) => {
      const playerObj = {
        name: player.name,
        playerId: player.playerId,
      }
      player.rounds.forEach((el) => {
        if (el.round - 1 === selectedRound && el.isCounted !== true) {
          excludedRounds.push({ ...playerObj, round: el })
        }
      })
    })
    let res = []
    if (excludedRounds[0]?.relativePar) {
      res = excludedRounds.sort((a, b) => Number(a.round.relativePar) - Number(b.round.relativePar))
    } else {
      res = excludedRounds.sort((a, b) => Number(a.round.stroke) - Number(b.round.stroke))
    }
    return res
  }

  const initialIncluded = getIncluded(round)
  const initialExcluded = getExcluded(round)

  const [included, setIncluded] = useState(initialIncluded)
  const [excluded, setExcluded] = useState(initialExcluded)
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  const handleRoundChange = (event, newValue) => {
    setRound(newValue)
    setIncluded(getIncluded(newValue))
    setExcluded(getExcluded(newValue))
  }

  const isTeamEvent = players[0].name.includes('/')

  const styles = useStyles()

  return (
    <div style={{ backgroundColor: 'inherit' }}>
      <div className={styles.tabsCloseRow}>
        <CloseButton className={styles.closeButton} onClick={handleToggle}><Close /></CloseButton>
        <RoundTabs value={round} onChange={handleRoundChange}>
          <RoundTab
            label={isMobile ? 'RD 1' : 'Round 1'}
            data-gtm-player-name={name}
            data-gtm-round={1}
          />
          {currentRound > 1 && (
            <RoundTab
              label={isMobile ? 'RD 2' : 'Round 2'}
              data-gtm-player-name={name}
              data-gtm-round={2}
            />
          )}
          {currentRound > 2 && (
            <RoundTab
              label={isMobile ? 'RD 3' : 'Round 3'}
              data-gtm-player-name={name}
              data-gtm-round={3}
            />
          )}
          {currentRound > 3 && (
            <RoundTab
              label={isMobile ? 'RD 4' : 'Round 4'}
              data-gtm-player-name={name}
              data-gtm-round={4}
            />
          )}
        </RoundTabs>
      </div>
      <Box className={styles.teamsContainer}>
        <Box className={styles.teamHeader} style={{ justifyContent: excluded.length > 0 ? 'space-between' : 'flex-end' }}>
          {excluded.length > 0 && (
            <Typography variant="overline">
              {`${isTeamEvent ? 'Teams ' : ''}included in scoring`}
            </Typography>
          )}
          <div className={`${styles.scoreWrapper} ${styles.scoreWrapperHeader}`}>
            <Typography variant="overline" className={`${styles.teamRowText} ${styles.hideMobile}`}>
              <div>TOTAL REL<span className={styles.hideSmall}> TO PAR</span></div>
            </Typography>
            <Typography variant="overline" className={styles.teamRowText}>
              TOTAL
            </Typography>
          </div>
        </Box>
        {included.map((el) => {
          return (
            <TeamStrokeInnerItem
              key={el.playerId}
              {...el}
              enableStatus={enableStatus}
              isMobile={isMobile}
              rounds={rounds}
              currentRound={round}
            />
          )
        })}
        {excluded.length > 0 && (
          <Box className={styles.teamHeader} style={{ justifyContent: excluded.length > 0 ? 'space-between' : 'flex-end' }}>
            <Typography variant="overline">
              {`${isTeamEvent ? 'Teams ' : ''}not included in scoring`}
            </Typography>
            <div className={`${styles.scoreWrapper} ${styles.scoreWrapperHeader}`}>
              <Typography variant="overline" className={`${styles.teamRowText} ${styles.hideMobile}`}>
                <div>TOTAL REL<span className={styles.hideSmall}> TO PAR</span></div>
              </Typography>
              <Typography variant="overline" className={styles.teamRowText}>
                TOTAL
              </Typography>
            </div>
          </Box>
        )}
        {excluded.map((el) => {
          return (
            <TeamStrokeInnerItem
              key={el.playerId}
              {...el}
              enableStatus={enableStatus}
              isMobile={isMobile}
              rounds={rounds}
              currentRound={round}
            />
          )
        })}
      </Box>
    </div>
  )
}

TeamStrokeInnerWrapper.propTypes = {
  currentRound: PropTypes.number,
  rounds: PropTypes.array,
  handleToggle: PropTypes.func,
  name: PropTypes.string,
  players: PropTypes.array,
  enableStatus: PropTypes.bool,
}

TeamStrokeInnerWrapper.defaultProps = {}

export default TeamStrokeInnerWrapper
