import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import EventHeadline from '@components/event-headline'
import Hero from '@components/hero'
import RichText from '@components/renderers/render-rich-text'
import defaults from '@config/defaults'
import ErrorBoundary from '@components/error/boundary'
import { NextSeo } from 'next-seo'
import { useSendImpressionEvent } from '@lib/gtm-utils'

const ParentLeaderboardPage = (props) => {
  const {
    headline,
    startDate,
    endDate,
    logo,
    feedId,
    tournamentId,
    heroImage,
    metaDescription,
    description,
    slug,
    tourName,
    watchLiveUrl,
    featuredButton,
    fullPage,
    children,
    trackingEventObject
  } = props

  const mt = heroImage ? [-6, -6, -12] : 6

  const now = DateTime.local()
  const eventInProgress = (now >= DateTime.fromISO(startDate)) && (now <= DateTime.fromISO(endDate))

  useSendImpressionEvent({ event: trackingEventObject?.event, attributes: trackingEventObject?.attributes })

  return (
    <article>
      {fullPage && (
        <NextSeo
          noindex={defaults.noindexNofollow}
          nofollow={defaults.noindexNofollow}
          title={tourName ? `Leaderboard - ${tourName} - ${headline}` : `Leaderboard - ${headline}`}
          description={metaDescription || description}
          canonical={`${defaults.baseUrl}events/${slug}/leaderboard`}
          openGraph={{
            title: headline,
            type: 'article',
            url: `${defaults.baseUrl}events/${slug}/leaderboard`,
            images: [{ url: (logo && logo.url) || (heroImage && heroImage.url) }],
          }}
        />
      )}

      {heroImage && fullPage && (
        <Hero
          variant="overlap"
          background={heroImage}
          heroText=""
        />
      )}

      {fullPage && (
        <Box component={Container} mt={mt}>
          <EventHeadline
            {...props}
            inProgress={eventInProgress}
            watchLiveUrl={watchLiveUrl}
            featuredButton={featuredButton}
          />
        </Box>
      )}

      {tournamentId && feedId ? (
        <div>{children}</div>

      ) : (
        <ErrorBoundary>
          <Container>
            <Box my={6}>
              <RichText {...description} />
            </Box>
          </Container>
        </ErrorBoundary>
      )}

    </article>
  )
}

ParentLeaderboardPage.propTypes = {
  enableStatus: PropTypes.bool,
  heroImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  headline: PropTypes.string,
  course: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  location: PropTypes.string,
  logo: PropTypes.shape({ url: PropTypes.string }),
  feedId: PropTypes.string,
  tournamentId: PropTypes.string,
  metaDescription: PropTypes.string,
  description: PropTypes.object,
  tourName: PropTypes.string,
  slug: PropTypes.string,
  watchLiveUrl: PropTypes.string,
  liveStreamUrl: PropTypes.string,
  nativeAdTitle: PropTypes.string,
  nativeAdDescription: PropTypes.string,
  nativeAdCta: PropTypes.string,
  nativeAdActive: PropTypes.bool,
  teamOf20PlayerIds: PropTypes.object,
  featuredButton: PropTypes.object,
  showExternalAd: PropTypes.bool,
  leaderboardPollInterval: PropTypes.number,
  fullPage: PropTypes.bool,
  children: PropTypes.object,
  trackingEventObject: PropTypes.object,
}

ParentLeaderboardPage.defaultProps = {}

export default ParentLeaderboardPage
