import { makeStyles, styled } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

export const EventHeadlineRoot = styled('div')(({ theme }) => ({
  background: theme.palette.grey[100],
  color: theme.palette.secondary.main,
  padding: theme.spacing(4),
}))

export const OuterBox = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  alignItems: 'center',
  borderRadius: '8px',
  boxShadow: theme.palette.shadow.cardBoxShadow,
}))

export const ImageBox = styled(Grid)(({ theme }) => ({
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  position: 'relative',
  minHeight: '144px',
  [theme.breakpoints.up('md')]: {
    minHeight: '190px',
  },
}))

export const MiddleBox = styled(Grid)(({ theme, haslogo }) => ({
  display: 'flex',
  borderTop: haslogo ? `1px solid ${theme.palette.divider}` : 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
  color: theme.palette.secondary.main,
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  padding: theme.spacing(3),
  justifyContent: 'center',
  textAlign: 'center',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    padding: 0,
    border: 'none',
    borderLeft: haslogo ? `1px solid ${theme.palette.divider}` : 'none',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderRight: `1px solid ${theme.palette.divider}`,
    textAlign: 'left',
    height: 'auto',
    margin: 0,
    paddingLeft: theme.spacing(4),
    marginTop: haslogo ? 0 : theme.spacing(3),
    marginBottom: haslogo ? 0 : theme.spacing(3),
    '& :first-child': {
      justifyContent: 'flex-start',
    },
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(8),
  },
}))

export const FieldsBox = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingTop: 'initial',
    paddingBottom: 'initial',
    paddingLeft: theme.spacing(8),
    height: 'auto',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  '&>div>div>svg': {
    overflow: 'visible',
  },
}))

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const FeaturedButton = styled(Button)(({ theme }) => ({
  height: '36px',
  [theme.breakpoints.down('sm')]: {
    margin: '1em',
  },
}))

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    '&>a': {
      outline: `2px solid ${theme.palette.primary.main}`,
    },
  },
  statusLine: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  statusLineText: {
    ...theme.typography.formInputs,
    lineHeight: '16px',
    marginLeft: '0.5625rem',
  },
}))

export default useStyles
