/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Fields from '@components/card/card-fields'
import WatchLiveButton from '@components/watch-live-button'
import IconCompleteNew from '@components/atoms/icons/icon-complete-new'
import IconDateFilledOutlinedAlt from '@components/atoms/icons/icon-date-filled-outlined-alt'
import IconLiveNew from '@components/atoms/icons/icon-live-new'
import { useTheme } from '@material-ui/core/styles'
import { DateTime } from 'luxon'
import useStyles, {
  ImageBox, MiddleBox, FieldsBox, OuterBox,
  ButtonContainer,
  FeaturedButton,
} from './event-headline.styles'


const EventHeadline = (props) => {
  const {
    headline,
    inProgress,
    watchLiveUrl,
    startDate,
    endDate,
    logo,
    tourName,
    featuredButton,
  } = props

  const styles = useStyles()
  const theme = useTheme()
  const liveText = 'Live'
  const upcomingText = 'Upcoming'
  let status = 'Complete'
  const now = DateTime.local()
  if (now < DateTime.fromISO(startDate)) status = upcomingText
  if ((now >= DateTime.fromISO(startDate)) && (now <= DateTime.fromISO(endDate))) status = liveText
  const liveLineText = tourName ? `${status} | ${tourName}` : status

  return (
    <OuterBox container data-cy="event-header">
      {logo && <ImageBox item xs={12} sm={2} style={{ backgroundImage: `url(${logo && logo.url})` }} />}

      <MiddleBox container item xs={12} sm={logo ? 6 : 8} haslogo={logo}>
        <Box mb={4} className={styles.statusLine}>
          {status === liveText ? <IconLiveNew viewBox="0 0 28 28" /> : status === upcomingText ? <IconDateFilledOutlinedAlt viewBox="0 0 28 28" /> : <IconCompleteNew viewBox="0 0 28 28" /> }
          <Typography
            variant="body1"
            className={styles.statusLineText}
          >{liveLineText}
          </Typography>
        </Box>
        <Box mt={0}>
          <Typography variant="h4" variantMapping={{ h4: 'h2' }} data-cy="event-headline">
            {headline}
          </Typography>
        </Box>
        <ButtonContainer mt={2}>
          {(inProgress && watchLiveUrl)
         && (
           <Box className={styles.buttonWrapper}>
             <WatchLiveButton href={watchLiveUrl} style={{ '&>a': { outline: `2px solid ${theme.palette.primary.main}` } }} />
           </Box>
         )
          }
          {(inProgress && featuredButton)
          && (
            <Box>
              <FeaturedButton href={featuredButton && featuredButton.href} size="small" color="secondary" variant="outlined">{featuredButton && featuredButton.text}</FeaturedButton>
            </Box>
          )
          }
        </ButtonContainer>

      </MiddleBox>
      <FieldsBox container item xs={12} sm={4} data-cy="event-card">
        <Fields {...props} />
      </FieldsBox>
    </OuterBox>
  )
}

EventHeadline.propTypes = {
  name: PropTypes.string,
  tourName: PropTypes.string,
  headline: PropTypes.string,
  course: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  location: PropTypes.string,
  logo: PropTypes.shape({ url: PropTypes.string }),
  watchLiveUrl: PropTypes.string,
  inProgress: PropTypes.bool,
  featuredButton: PropTypes.shape({ text: PropTypes.string, href: PropTypes.string }),
}

EventHeadline.defaultProps = {
  name: '',
  headline: '',
  course: '',
  startDate: '',
  endDate: '',
  location: '',
  logo: { url: '' },
}

export default EventHeadline
