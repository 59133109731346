import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import useStyles from '@components/leaderboard/leaderboard.style-hook'
import TeamStrokeLeaderboardItem from './team-stroke-leaderboard-item'
import TeamLeaderboardHead from './team-leaderboard-head'
import { LeaderboardContext, actionTypes } from './team-leaderboard.provider'

const MyLeaderboard = React.memo((props) => {
  const {
    id,
    feedId,
    limit,
    enableScorecard,
    bordered,
    rounds = 4,
    strokeTeamLeaderboards,
  } = props

  const styles = useStyles()
  const updatedTeams = strokeTeamLeaderboards
  const { state, dispatch } = useContext(LeaderboardContext)
  const { teams } = state[id] || []

  const removeTeamFav = (team) => {
    dispatch({ type: actionTypes.REMOVE_TEAM, payload: { tournamentId: id, team } })
  }

  const filterTeams = (favTeams, allTeams) => {
    if (!favTeams || !allTeams) { return false }
    const outTeams = []
    allTeams.forEach((allTeam) => {
      favTeams.forEach((favTeam) => {
        if (allTeam && favTeam
          && allTeam.teamId === favTeam.teamId) {
          outTeams.push(allTeam)
        }
      })
    })
    return outTeams
  }

  const filteredTeams = filterTeams(teams, updatedTeams)
  const orderedTeams = filteredTeams
    ? filteredTeams.sort((a, b) => a.teamPosition - b.teamPosition)
    : []
  const show = limit ? orderedTeams.slice(0, limit) : orderedTeams

  return show.length > 0 ? (
    <Box py={[4, 6, 8]} bgcolor="grey.200" mt={[4, 6, 8]}>
      <Container
        data-cy="my-leaderboard"
        className={styles.myLeaderboard}
      >
        {enableScorecard && (
          <>
            <div>
              <Typography variant="subtitle2" className={styles.descriptionTitle}>My Leaderboard</Typography>
              <TeamLeaderboardHead rounds={rounds} showFavorite />
            </div>

            {show && show.map(team => (
              team && (
                <TeamStrokeLeaderboardItem
                  key={team.teamId}
                  bordered={bordered}
                  {...team}
                  tournamentId={id}
                  feedId={feedId}
                  enableScorecard={enableScorecard}
                  totalRounds={rounds}
                  removeItem={removeTeamFav}
                  saved
                  leaderboardSaved
                  showFavorite
                />
              )
            ))}
          </>
        )}
      </Container>
    </Box>
  ) : null
})

MyLeaderboard.propTypes = {
  bordered: PropTypes.bool,
  enableScorecard: PropTypes.bool,
  feedId: PropTypes.string,
  id: PropTypes.string,
  limit: PropTypes.number,
  rounds: PropTypes.number,
  strokeTeamLeaderboards: PropTypes.array,
}

MyLeaderboard.defaultProps = {
}

export default MyLeaderboard
