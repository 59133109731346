/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types'
import { Box, Typography, Container } from '@material-ui/core'
import RolexClock from '@components/rolex-clock/index'
import Disclaimer from '@components/leaderboard/leaderboard-disclaimer'
import LeaderboardOuterItem from '@components/leaderboard-scramble/leaderboard-outer-item'
import { useState } from 'react'
import { LeaderboardQuery } from '@components/leaderboard'
import { useStyles } from '../leaderboard-scramble/scramble-leaderboard.style-hook'
import TeamLeaderboard from './team-leaderboard'

const TeamLeaderboardContainer = (props) => {
  const {
    showExternalAd,
    fullPage,
    updatedAt,
    eventTournamentIds,
    strokeTeamLeaderboards,
    currentRoundState,
    rounds = 4,
    currentRound,
    feedId,
    enableStatus,
    liveStreamUrl,
    nativeAdTitle,
    nativeAdDescription,
    nativeAdCta,
    nativeAdActive,
    limit,
  } = props

  const tournamentRounds = eventTournamentIds.team.totalRounds || rounds
  const isTournamentComplete = Number(currentRound) >= tournamentRounds && currentRoundState === 'Completed'

  const styles = useStyles({ fullPage })

  const sortedLeaderboardItems = strokeTeamLeaderboards.sort((a, b) => {
    const aPosition = a.teamPosition === null ? '' : (a.teamPosition[0] === 'T' ? Number(a.teamPosition.slice(1)) : Number(a.teamPosition))
    const bPosition = b.teamPosition === null ? '' : (b.teamPosition[0] === 'T' ? Number(b.teamPosition.slice(1)) : Number(b.teamPosition))
    if (aPosition < bPosition) return -1
    if (aPosition > bPosition) return 1
    return 0
  })

  const [showLeaderboard, toggleLeaderboard] = useState(false)

  function handleToggle() {
    toggleLeaderboard(!showLeaderboard)
  }

  return (
    <Box className={styles.pageWrapper}>
      <Box display="flex" alignItems="flex-end" pt={[showExternalAd ? 2 : 0, 0, 0]}>
        {fullPage && (
          <Box className={styles.disclaimerWrapper}>
            <div>
              <Typography variant="subtitle2" className={styles.fullLeaderboard}>Full Leaderboard</Typography>
              <Disclaimer className={styles.disclaimer} updatedAt={updatedAt} />
            </div>
            <RolexClock showExternalAd={showExternalAd} />
          </Box>
        )}
      </Box>
      <Box>
        <Container className={styles.disclaimerLanding}>
          {!fullPage && <Disclaimer className={styles.disclaimer} updatedAt={updatedAt} />}
        </Container>
        <Box py={[2, 2, 1]}>
          <Container>
            <LeaderboardOuterItem
              matchName="Team"
              isTournamentComplete={isTournamentComplete}
              eventStage={null}
              winner={sortedLeaderboardItems[0].teamName}
              handleToggle={handleToggle}
              showLeaderboard={showLeaderboard}
            />
          </Container>
          {showLeaderboard && <TeamLeaderboard {...props} id={eventTournamentIds.team.tournamentId} sorted={sortedLeaderboardItems} nested />}
        </Box>
        <Box py={[2, 2, 1]}>
          <LeaderboardQuery
            id={eventTournamentIds.individual.tournamentId}
            feedId={feedId}
            enableScorecard
            enableStatus={enableStatus}
            liveStreamUrl={liveStreamUrl}
            nativeAdTitle={nativeAdTitle}
            nativeAdDescription={nativeAdDescription}
            nativeAdCta={nativeAdCta}
            nativeAdActive={nativeAdActive}
            showExternalAd={showExternalAd}
            totalRounds={eventTournamentIds.individual.totalRounds}
            leaderboardPlaces={String(limit)}
            nested
          />
        </Box>
      </Box>
    </Box>
  )
}

TeamLeaderboardContainer.propTypes = {
  showExternalAd: PropTypes.bool,
  fullPage: PropTypes.bool,
  updatedAt: PropTypes.string,
  eventTournamentIds: PropTypes.shape({
    qualifier: PropTypes.object,
    semi1: PropTypes.object,
    semi2: PropTypes.object,
    final: PropTypes.object,
    consolation: PropTypes.object,
    team: PropTypes.object,
    individual: PropTypes.object,
  }),
  strokeTeamLeaderboards: PropTypes.array,
  currentRoundState: PropTypes.string,
  rounds: PropTypes.number,
  currentRound: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  totalRounds: PropTypes.number,
  feedId: PropTypes.string,
  enableStatus: PropTypes.bool,
  liveStreamUrl: PropTypes.string,
  nativeAdTitle: PropTypes.string,
  nativeAdDescription: PropTypes.string,
  nativeAdCta: PropTypes.string,
  nativeAdActive: PropTypes.bool,
  limit: PropTypes.number,
}

TeamLeaderboardContainer.defaultProps = {
}

export default TeamLeaderboardContainer
