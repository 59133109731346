import { styled, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  field: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '22px',
    marginBottom: theme.spacing(1),
  },
  fieldText: {
    ...theme.typography.body1,
    fontWeight: '600',
    textTransform: 'capitalize',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    marginLeft: '-4px',
    marginTop: -1,
    [theme.breakpoints.down('sm')]: {
      scale: 0.8,
    },
  },
  iconTicket: {
    marginRight: 14,
    marginLeft: -6,
    marginTop: -1,
    transform: 'scale(0.9)',
  },
  ticketLink: {
    alignItems: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  ticktLinkArrow: {
    marginLeft: theme.spacing(2),
    marginTop: -1,
  },
}))

export const CardRoot = styled('div')(({ theme }) => ({
  background: theme.palette.secondary.light,
  color: theme.palette.getContrastText(theme.palette.secondary.light),
  padding: theme.spacing(1),
}))

export const CardEventBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.background.paper,
  position: 'relative',
}))

export const CardContentBox = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  '& img': {
    maxWidth: '100%',
  },
}))

export const Image = styled('img')(() => ({
  display: 'block',
  maxWidth: '100%',
  width: '100%',
}))

export const CardFieldBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: '20px',
  marginBottom: theme.spacing(1),
  '& svg': {
    marginRight: theme.spacing(1),
    marginLeft: '-4px',
  },
}))

export const SlimBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  background: theme.palette.background.paper,
  position: 'relative',
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
  },
}))

export const SlimColBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  width: '50%',
  borderColor: theme.palette.grey[200],
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    border: 'none',
  },
}))

export const SlimCtaBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('xs')]: {
    alignSelf: 'flex-end',
  },
}))

export const Cta = styled(Box)(() => ({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'flex-end',
  alignSelf: 'flex-end',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  letterSpacing: 1.25,
  '& svg': {
    position: 'relative',
    top: 5,
    left: 8,
  },
}))

export default useStyles
