/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import TrophyIcon from '@components/atoms/icons/trophy'
import { useStyles } from './team-leaderboard-item.styles'
import TeamStrokeInnerWrapper from './team-stroke-inner-wrapper'

const cutOrWithdrawn = (currentPosition) => {
  return currentPosition === 'WD' || currentPosition === 'CUT'
}

const formatScore = (score) => {
  if (!score) return '-'
  if (score === 'E' || score === '0') return 'E'
  const scoreNum = Number(score)
  if (scoreNum > 0) return `+${scoreNum}`
  return scoreNum
}

const formatRound = (round, currentPosition) => {
  if (cutOrWithdrawn(currentPosition)) {
    return '-'
  }
  return round?.relativePar ? formatScore(round.relativePar) : '-'
}

const TeamStrokeLeaderboardItem = React.memo((props) => {
  const [showScorecard, toggleScoreCard] = useState(false)
  const theme = useTheme()
  const styles = useStyles()
  const {
    bordered,
    players,
    tournamentRelativePar,
    currentRound,
    rounds,
    addItem,
    removeItem,
    saved,
    streamUrl,
    liveStreamUrl,
    leaderboardSaved,
    showFavorite,
    teamName,
    teamPosition,
    winner,
    totalRounds,
  } = props

  const sortedRounds = [...rounds].sort((a, b) => a.round - b.round)
  const totalScoreToPar = rounds.reduce((acc, curr) => {
    const num = curr.relativePar === 'E' ? 0 : Number(curr.relativePar)
    return acc + num
  }, 0)
  const tournRelPar = tournamentRelativePar || totalScoreToPar
  const currRound = currentRound || rounds.length
  const [currRoundObj] = rounds.filter(el => el.round === currRound)

  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))
  const underPar = tournRelPar < 0
  const tournamentRelativeParFixed = formatScore(tournRelPar)

  function handleToggle() {
    if (window && window.dataLayer) {
      window.dataLayer.push({
        teamName,
      })
    }

    if (players) toggleScoreCard(!showScorecard)
  }

  const clickStar = (e) => {
    e.stopPropagation()
    if (saved) {
      removeItem(props)
    } else {
      addItem(props)
    }
  }

  const formatDisplayScore = (roundNumber) => {
    if (sortedRounds.length < 1) {
      return '-'
    }
    if (currRound < roundNumber) {
      return '-'
    }
    return formatRound(sortedRounds[roundNumber - 1])
  }

  const moveShow = currRoundObj.move < 0 ? currRoundObj.move * -1 : (currRoundObj.move || 0)

  return (
    <div className={styles.outer} data-cy="leaderboard-item">
      <div
        className={`${styles.item} ${bordered ? styles.bordered : ''}`}
        role="row"
        onClick={handleToggle}
        tabIndex={0}
      >
        {showFavorite && (
          <IconButton
            aria-label="favorite"
            onClick={clickStar}
            role="button"
          >
            {saved ? (
              <StarIcon color={leaderboardSaved && 'secondary'} />
            ) : (
              <StarBorderIcon />
            )}
          </IconButton>
        )}
        <Box
          className={styles.rank}
          pl={showFavorite ? 0 : 2}
          role="cell"
          aria-label={`position: ${teamPosition}`}
        >
          {teamPosition}
        </Box>
        <Box
          // eslint-disable-next-line no-nested-ternary
          className={`${styles.move} ${styles.hideMobile} ${moveShow === 0 ? styles.moveNone : currRoundObj.move > 0 ? styles.moveUp : styles.moveDown}`}
          pl={showFavorite ? 0 : 2}
          role="cell"
          aria-label={`move: ${currRoundObj.move}`}
        >
          {moveShow !== 0 && <ArrowDropDownIcon className={`${styles.moveArrow} ${currRoundObj.move > 0 && styles.moveUpArrow}`} />}
          {moveShow || '-'}
        </Box>
        <div className={`${styles.itemName} ${rounds ? styles.clickHover : null}`}>
          <Typography className={styles.lastName} variant="h6">
            {teamName}
          </Typography>
          <KeyboardArrowDownIcon className={`${styles.chevron} ${showScorecard ? 'expanded' : ''}`} />
          {winner && <TrophyIcon />}
        </div>
        {streamUrl && liveStreamUrl && (
          <div className={`${styles.itemStreamUrl} ${styles.hideMobile}`}>
            <a href={liveStreamUrl} target="_blank" rel="noopener noreferrer">
              <Tooltip title="Watch Featured Groups">
                <div className={styles.liveStreamIcon} />
              </Tooltip>
            </a>
          </div>
        )}
        <div
          className={styles.itemValue}
          style={{ color: underPar ? theme.palette.accent.red : null }}
          role="cell"
          aria-label={`total score: ${tournamentRelativeParFixed}`}
        >
          {tournamentRelativeParFixed}
        </div>
        <div
          className={`${styles.itemValue} ${styles.hideMobile}`}
          role="cell"
          aria-label={`first round score: ${rounds ? formatRound(sortedRounds[0]) : '-'}`}
        >
          {formatDisplayScore(1)}
        </div>
        {totalRounds >= 2 && (
          <div
            className={`${styles.itemValue} ${styles.hideMobile}`}
            role="cell"
            aria-label={`second round score: ${rounds ? formatRound(sortedRounds[1]) : '-'}`}
          >
            {formatDisplayScore(2)}
          </div>
        )}
        {totalRounds >= 3 && (
          <div
            className={`${styles.itemValue} ${styles.hideMobile}`}
            role="cell"
            aria-label={`third round score: ${rounds ? formatRound(sortedRounds[2]) : '-'}`}
          >
            {formatDisplayScore(3)}
          </div>
        )}
        {totalRounds >= 4 && (
          <div
            className={`${styles.itemValue} ${styles.hideMobile}`}
            role="cell"
            aria-label={`fourth round score: ${rounds ? formatRound(sortedRounds[3]) : '-'}`}
          >
            {formatDisplayScore(4)}
          </div>
        )}
      </div>

      {showScorecard && (
        <Box
          position="relative"
          pt={[4, 0]}
          pl={[0, 0, 10]}
          pr={[0, 0, 4]}
          pb={2}
          className={styles.inner}
        >
          <TeamStrokeInnerWrapper
            {...props}
            currentRound={currRound}
            handleToggle={() => handleToggle()}
            isMobile={isMobile}
          />
        </Box>
      )}
    </div>
  )
})

TeamStrokeLeaderboardItem.propTypes = {
  bordered: PropTypes.bool,
  currentPosition: PropTypes.string,
  feedId: PropTypes.string,
  tournamentId: PropTypes.string,
  player: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    playerId: PropTypes.string,
    country: PropTypes.string,
  }),
  streamUrl: PropTypes.string,
  liveStreamUrl: PropTypes.string,
  tournamentRelativePar: PropTypes.number,
  teeTime: PropTypes.string,
  thru: PropTypes.string,
  currentRound: PropTypes.number,
  currentRelativePar: PropTypes.string,
  rounds: PropTypes.arrayOf(
    PropTypes.shape({
      stroke: PropTypes.number,
    }),
  ),
  tournamentStreak: PropTypes.number,
  totalRounds: PropTypes.number,
  saved: PropTypes.bool,
  leaderboardSaved: PropTypes.bool,
  addItem: PropTypes.func,
  removeItem: PropTypes.func,
  enableStatus: PropTypes.bool,
  showFavorite: PropTypes.bool,
  players: PropTypes.array,
  teamName: PropTypes.string,
  teamPosition: PropTypes.string,
  winner: PropTypes.bool,
}

TeamStrokeLeaderboardItem.defaultProps = {}

export default TeamStrokeLeaderboardItem
