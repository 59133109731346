/* eslint-disable no-nested-ternary */
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Container } from '@material-ui/core'
import RolexClock from '@components/rolex-clock/index'
import Disclaimer from '@components/leaderboard/leaderboard-disclaimer'
import { useStyles, RoundTabs, RoundTab } from './scramble-leaderboard.style-hook'
import TeamLeaderboard from '../leaderboard-team/team-leaderboard'
import FlagLeaderboardWithData from './flag/flag-leaderboard.query'

const ScrambleLeaderboardContainer = (props) => {
  const {
    showExternalAd,
    fullPage,
    updatedAt,
    eventTournamentIds,
    strokeTeamLeaderboards,
  } = props

  const styles = useStyles({ fullPage })
  const [stage, setStage] = useState(0)

  const stagesDict = {
    0: eventTournamentIds.qualifier.tournamentId ? 'Qualifier' : '',
    1: eventTournamentIds.semi1.tournamentId || eventTournamentIds.semi2.tournamentId ? 'Semifinals' : '',
    2: eventTournamentIds.final.tournamentId || eventTournamentIds.consolation.tournamentId ? 'Finals' : '',
  }

  function handleTagChange(event, newValue) {
    if (newValue !== stage) {
      setStage(newValue)
    }
  }

  return (
    <Box className={styles.pageWrapper}>
      <Box display="flex" alignItems="flex-end" pt={[showExternalAd ? 2 : 0, 0, 0]}>
        {fullPage && (
          <Box className={styles.disclaimerWrapper}>
            <div>
              <Typography variant="subtitle2" className={styles.fullLeaderboard}>Full Leaderboard</Typography>
              <Disclaimer className={styles.disclaimer} updatedAt={updatedAt} />
            </div>
            <RolexClock showExternalAd={showExternalAd} />
          </Box>
        )}
      </Box>
      <Container>
        <RoundTabs style={{ marginBottom: 12 }} value={stage} onChange={handleTagChange}>
          {stagesDict[0] && <RoundTab label={stagesDict[0]} />}
          {stagesDict[1] && <RoundTab label={stagesDict[1]} />}
          {stagesDict[2] && <RoundTab label={stagesDict[2]} />}
        </RoundTabs>
      </Container>
      <Box>
        <Container className={styles.disclaimerLanding}>
          {!fullPage && <Disclaimer className={styles.disclaimer} updatedAt={updatedAt} />}
        </Container>
        {stage === 0 && (
          <TeamLeaderboard
            {...props}
            sorted={strokeTeamLeaderboards}
            totalRounds={eventTournamentIds.qualifier.totalRounds}
          />
        )}
        {stage === 1 && (
          <>
            {eventTournamentIds.semi1.tournamentId && (
              <FlagLeaderboardWithData
                {...props}
                id={eventTournamentIds.semi1.tournamentId}
                totalFlags={eventTournamentIds.semi1.totalFlags}
              />
            )}
            {eventTournamentIds.semi2.tournamentId && (
              <FlagLeaderboardWithData
                {...props}
                id={eventTournamentIds.semi2.tournamentId}
                totalFlags={eventTournamentIds.semi2.totalFlags}
              />
            )}
          </>
        )}
        {stage === 2 && (
          <>
            {eventTournamentIds.final.tournamentId && (
              <FlagLeaderboardWithData
                {...props}
                id={eventTournamentIds.final.tournamentId}
                totalFlags={eventTournamentIds.final.totalFlags}
                eventStage="final"
              />
            )}
            {eventTournamentIds.consolation.tournamentId && (
              <FlagLeaderboardWithData
                {...props}
                id={eventTournamentIds.consolation.tournamentId}
                totalFlags={eventTournamentIds.consolation.totalFlags}
                eventStage="consolation"
              />
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

ScrambleLeaderboardContainer.propTypes = {
  showExternalAd: PropTypes.bool,
  fullPage: PropTypes.bool,
  updatedAt: PropTypes.string,
  eventTournamentIds: PropTypes.shape({
    qualifier: PropTypes.shape({
      tournamentId: PropTypes.string,
      totalRounds: PropTypes.number,
    }),
    semi1: PropTypes.shape({
      tournamentId: PropTypes.string,
      totalFlags: PropTypes.number,
    }),
    semi2: PropTypes.shape({
      tournamentId: PropTypes.string,
      totalFlags: PropTypes.number,
    }),
    final: PropTypes.shape({
      tournamentId: PropTypes.string,
      totalFlags: PropTypes.number,
    }),
    consolation: PropTypes.shape({
      tournamentId: PropTypes.string,
      totalFlags: PropTypes.number,
    }),
  }),
  strokeTeamLeaderboards: PropTypes.array,
}

ScrambleLeaderboardContainer.defaultProps = {
}

export default ScrambleLeaderboardContainer
