import PropTypes from 'prop-types'
import ButtonLink from '@components/atoms/button-link'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'

import { BrandedButton } from './watch-live-button.styles'

const BrandedWatchLiveButton = (props) => {
  const { href: externalLink, ...rest } = props
  return (
    <BrandedButton
      component={ButtonLink}
      variant="outlined"
      color="primary"
      size="small"
      startIcon={<PlayArrowIcon />}
      externalLink={externalLink}
      {...rest}
    >
      Watch Live
    </BrandedButton>
  )
}

BrandedWatchLiveButton.propTypes = {
  href: PropTypes.string,
}

BrandedWatchLiveButton.defaultProps = {}

export default BrandedWatchLiveButton
