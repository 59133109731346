import Typography from '@material-ui/core/Typography'
import {
  Win,
  Start,
  LegendContainer,
  Legend,
} from '../scramble-leaderboard.style-hook'

const FlagScoring = () => {
  return (
    <LegendContainer>
      <Legend>
        <Start>#</Start>
        <Typography variant="caption" style={{ paddingLeft: 5 }}>Start</Typography>
      </Legend>
      <Legend>
        <Win>#</Win>
        <Typography variant="caption" style={{ paddingLeft: 5 }}>Win</Typography>
      </Legend>
    </LegendContainer>
  )
}


export default FlagScoring
