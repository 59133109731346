import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { useState } from 'react'
import {
  useStyles,
} from '@components/leaderboard-scramble/scramble-leaderboard.style-hook'
import TeamStrokeScorecard from './team-stroke-scorecard'


export const checkTurn = (turn, thisTurn) => {
  return turn === thisTurn ? 'flex' : 'none'
}

const TeamStrokeInnerItem = (props) => {
  const {
    rounds,
    round,
    isMobile,
    name,
    enableStatus,
    currentRound,
  } = props

  const styles = useStyles()
  const [showScorecard, setShowScorecard] = useState(false)

  function handleToggle() {
    setShowScorecard(!showScorecard)
  }

  function isTurnComplete(holes) {
    let finished = true
    holes.forEach((hole) => {
      const score = Number(hole.score)
      if (score < 1) finished = false
    })
    return finished
  }

  const addFields = (array = [], val) => array
    .map(item => parseInt(item[val], 10) || 0)
    .reduce((prev, next) => prev + next, 0)

  const calcRelPar = (array) => {
    const temp = []
    const res = []
    for (let i = 0; i < array.length; i += 1) {
      const diff = (array[i].score || 0) - (array[i].par || 0)
      temp.push(diff)
    }
    for (let i = 0; i < temp.length; i += 1) {
      const added = temp.slice(0, i + 1).reduce((acc, curr) => acc + curr, 0)
      res.push(added)
    }
    for (let i = 0; i < temp.length; i += 1) {
      if (res[i] === 0) {
        res[i] = 'E'
      }
    }
    return res
  }

  const card = round && round.holes
  const sortedRounds = card && [...card].sort(({ hole: a }, { hole: b }) => a - b)

  const front = sortedRounds && sortedRounds.slice(0, 9)
  const frontCalculated = isTurnComplete(front) ? addFields(front, 'score') : 0

  const back = sortedRounds && sortedRounds.slice(9, 18)
  const backCalculated = isTurnComplete(back) ? addFields(back, 'score') : 0

  const outPar = addFields(front, 'par')
  const inPar = addFields(back, 'par')
  const totalPar = outPar + inPar

  const outYards = addFields(front, 'yards')
  const inYards = addFields(back, 'yards')
  const totalYards = outYards + inYards

  const totalCompleteScore = backCalculated + frontCalculated
  const totalRunningScore = addFields(sortedRounds, 'score')

  const relScoreArr = calcRelPar([...front, ...back])
  const outRelScoreArr = relScoreArr.slice(0, 9)
  const inRelScoreArr = relScoreArr.slice(9, 18)
  let outRelScore = (frontCalculated - outPar) === 0 ? 'E' : (frontCalculated - outPar)
  let inRelScore = (backCalculated - inPar) === 0 ? 'E' : (backCalculated - inPar)
  let totalRelScore = (totalCompleteScore - totalPar) === 0 ? 'E' : (totalCompleteScore - totalPar)

  if (outRelScore > 0) outRelScore = `+${outRelScore}`
  if (inRelScore > 0) inRelScore = `+${inRelScore}`
  if (totalRelScore > 0) totalRelScore = `+${totalRelScore}`

  const [courseObj] = rounds.filter(el => el.round - 1 === currentRound)

  let totalRelPar = round.relativePar > 0 ? `+${round.relativePar}` : round.relativePar
  if (!totalRelPar || totalRelPar === 'null') {
    totalRelPar = totalRelScore
  }
  if (totalRelPar === '0') totalRelPar = 'E'
  if (totalRunningScore === 0 || totalPar === 0) totalRelPar = '-'

  const getRoundStatus = () => {
    if (card.length < 1) return false
    for (let i = 0; i < 9; i += 1) {
      if (back[i]?.score < 1 && back[i]?.par > 0) return false
      if (front[i]?.score < 1 && front[i]?.par > 0) return false
    }
    return true
  }

  const isRoundComplete = getRoundStatus()
  const totalDisplayScore = isRoundComplete ? (round.stroke || totalCompleteScore) : '-'

  const formatName = () => {
    const split = name.split('/')
    if (split.length > 1) {
      return `${split[0]} / ${split[1]}`
    }
    return name
  }

  const showBack9 = (back[0] && back[0].score) || false
  const [turn, setTurn] = useState(showBack9 ? 'back' : 'front')

  function handleTurnChange(event, newValue) {
    setTurn(newValue)
  }

  return (
    <Box className={styles.teamSection}>
      <Box onClick={() => handleToggle()} className={styles.teamRow}>
        <Box className={styles.left}>
          <Typography className={styles.teamRowText}>
            {formatName()}
          </Typography>
          <KeyboardArrowDownIcon className={`${styles.chevron} ${showScorecard ? 'expanded' : ''}`} />
        </Box>
        <div className={styles.scoreWrapper}>
          <Typography className={`${styles.teamRowText} ${styles.leftItem}`}>
            {totalRelPar}
          </Typography>
          <Typography className={`${styles.teamRowText} ${styles.hideMobile}`}>
            {totalDisplayScore}
          </Typography>
        </div>
      </Box>
      {showScorecard && (
        <Box
          className={styles.internalScorecard}
          pt={[4, 0]}
          pb={2}
        >
          <TeamStrokeScorecard
            front={front}
            back={back}
            outPar={outPar}
            inPar={inPar}
            totalPar={totalPar}
            outYards={outYards}
            inYards={inYards}
            totalYards={totalYards}
            outScore={frontCalculated}
            inScore={backCalculated}
            totalScore={totalCompleteScore}
            enableStatus={enableStatus}
            outRelScoreArr={outRelScoreArr}
            inRelScoreArr={inRelScoreArr}
            outRelScore={String(outRelScore)}
            inRelScore={String(inRelScore)}
            totalRelScore={String(totalRelPar)}
            courseName={courseObj.courseName}
            isMobile={isMobile}
            turn={turn}
            handleTurnChange={() => handleTurnChange()}
          />
        </Box>
      )}
    </Box>
  )
}

TeamStrokeInnerItem.propTypes = {
  round: PropTypes.object,
  name: PropTypes.string,
  rounds: PropTypes.arrayOf(PropTypes.shape),
  isMobile: PropTypes.bool,
  enableStatus: PropTypes.bool,
  currentRound: PropTypes.number,
}

TeamStrokeInnerItem.defaultProps = {
}

export default TeamStrokeInnerItem
