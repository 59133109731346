import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import ScorecardScoring from '@components/scorecard/scorecard-scoring'
import Typography from '@material-ui/core/Typography'
import ScoreCell from './team-stroke-score-cell'
import {
  useStyles,
  ScorecardRoot,
  TurnBox,
  ColBox,
  FirstCol,
  CellBox,
  ScoreCellBox,
  FirstCell,
} from './team-stroke-scorecard.styles'

export const checkTurn = (turn, thisTurn) => {
  return turn === thisTurn ? 'flex' : 'none'
}

const TeamStrokeScorecard = (props) => {
  const {
    front,
    back,
    isMobile,
    totalYards,
    totalPar,
    totalScore,
    enableStatus,
    outRelScoreArr,
    inRelScoreArr,
    totalRelScore,
    courseName,
  } = props

  const showFront = !!front[0]?.par
  const showBack = !!back[0]?.par
  const turnValue = showFront ? 'front' : 'back'

  const styles = useStyles()

  return (
    <ScorecardRoot>

      <Box display="flex" flexDirection="column">
        <Typography variant="h6" className={styles.courseName}>{courseName}</Typography>
        <Box display="flex">
          {showFront && (
            <TurnBox
              style={{ display: isMobile ? checkTurn(turnValue, 'front') : 'flex' }}
            >
              <FirstCol>
                <FirstCell><div>Hole</div></FirstCell>
                <FirstCell><div>Yards</div></FirstCell>
                <FirstCell><div>Par</div></FirstCell>
                <FirstCell><div>Score</div></FirstCell>
                {enableStatus && <FirstCell><div>Status</div></FirstCell>}
              </FirstCol>

              {front.map((cell, i) => (
                <ScoreCell
                  className={styles.cellBox}
                  {...cell}
                  enableStatus={enableStatus}
                  key={`${cell.score}-${cell.hole}`}
                  status={outRelScoreArr[i] > 0 ? `+${outRelScoreArr[i]}` : String(outRelScoreArr[i])}
                />
              ))}

            </TurnBox>
          )}

          {showBack && (
            <TurnBox
              style={{ display: isMobile ? checkTurn(turnValue, 'back') : 'flex' }}
            >
              <FirstCol>
                <FirstCell><div>Hole</div></FirstCell>
                <FirstCell><div>Yards</div></FirstCell>
                <FirstCell><div>Par</div></FirstCell>
                <FirstCell><div>Score</div></FirstCell>
                {enableStatus && <FirstCell><div>Status</div></FirstCell>}
              </FirstCol>
              {back.map((cell, i) => (
                <ScoreCell
                  className={styles.cellBox}
                  {...cell}
                  enableStatus={enableStatus}
                  key={`${cell.score}-${cell.hole}`}
                  status={inRelScoreArr[i] > 0 ? `+${inRelScoreArr[i]}` : String(inRelScoreArr[i])}
                />
              ))}

            </TurnBox>
          )}
          <ColBox>
            <CellBox><span className={styles.lastCell}>Tot</span></CellBox>
            <CellBox className={styles.lastCell}>{totalYards || '-'}</CellBox>
            <CellBox><span className={styles.lastCell} data-cy="total">{totalPar || '-'}</span></CellBox>
            <ScoreCellBox>
              <span className={styles.lastCell} data-cy="total-score">{totalScore || '-'}</span>
            </ScoreCellBox>
            {enableStatus && (
              <ScoreCellBox>
                <span className={styles.lastCell}>
                  {(totalScore === '-' || totalScore === 0) ? '-' : totalRelScore}
                </span>
              </ScoreCellBox>
            )}
          </ColBox>
        </Box>
      </Box>

      <ScorecardScoring />

    </ScorecardRoot>
  )
}

TeamStrokeScorecard.propTypes = {
  turn: PropTypes.string,
  handleTurnChange: PropTypes.func,
  front: PropTypes.arrayOf(PropTypes.shape),
  back: PropTypes.arrayOf(PropTypes.shape),
  isMobile: PropTypes.bool,
  inPar: PropTypes.number,
  inScore: PropTypes.number,
  outPar: PropTypes.number,
  outScore: PropTypes.number,
  totalScore: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  enableStatus: PropTypes.bool,
  outYards: PropTypes.number,
  inYards: PropTypes.number,
  totalYards: PropTypes.number,
  outRelScoreArr: PropTypes.array,
  inRelScoreArr: PropTypes.array,
  outRelScore: PropTypes.string,
  inRelScore: PropTypes.string,
  totalRelScore: PropTypes.string,
  courseName: PropTypes.string,
  totalPar: PropTypes.number,
}

TeamStrokeScorecard.defaultProps = {
  front: [],
  back: [],
}

export default TeamStrokeScorecard
