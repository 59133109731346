import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: ({ background, gradient = theme.palette.background.gradient }) => ({
    backgroundColor: theme.palette.grey[300],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `${gradient}, url(${background && background.url}?h=320&w=375&fit=fill&fl=progressive&q=80)`,
    display: 'flex',
    alignItems: 'flex-end',
    color: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `${gradient}, url(${background && background.url}?h=450&w=1440&fit=fill&fl=progressive&q=80)`,
    },
    '& a, & a:hover': {
      textDecoration: 'none',
      color: 'white',
    },
    '& time': {
      textTransform: 'uppercase',
      fontSize: 11,
    },
    '& .hero--cta': {
      fontSize: 12,
      padding: '.25em 1em',
      borderRadius: '2em',
    },
    '& a:hover .hero--cta': {
      backgroundColor: 'rgba(255, 255, 255, .25)',
    },
  }),
}))
