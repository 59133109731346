import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'

const actionTypes = {
  SET_INITIAL_STATE: 'setInitialstate',
  ADD_TEAM: 'addTeam',
  REMOVE_TEAM: 'removeTeam',
}

const initialState = {}

const reducer = (state, action) => {
  switch (action.type) {
  case actionTypes.SET_INITIAL_STATE: {
    return action.payload.state
  }
  case actionTypes.ADD_TEAM: {
    const tournament = state[action.payload.tournamentId]
    const favTeams = tournament ? tournament.teams : []
    const newState = {
      ...state,
      [action.payload.tournamentId]: { teams: [...favTeams, action.payload.team] },
    }
    localStorage.setItem('myTeamLeaderBoard', JSON.stringify(newState))
    return newState
  }
  case actionTypes.REMOVE_TEAM: {
    const tournament = state[action.payload.tournamentId]
    const targetTeamId = action.payload.team.teamId
    const otherTeams = tournament
      ? tournament.teams.filter(item => targetTeamId !== item.teamId)
      : []
    const newState = { ...state, [action.payload.tournamentId]: { teams: otherTeams } }
    localStorage.setItem('myTeamLeaderBoard', JSON.stringify(newState))
    return newState
  }
  default:
    return { ...state }
  }
}

const LeaderboardContext = React.createContext(initialState)

const LeaderboardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(
    () => {
      dispatch({
        type: actionTypes.SET_INITIAL_STATE,
        payload: { state: JSON.parse(localStorage.getItem('myTeamLeaderBoard')) || {} },
      })
    },
    [],
  )

  return (
    <LeaderboardContext.Provider value={{ state, dispatch }}>
      {children}
    </LeaderboardContext.Provider>
  )
}


LeaderboardProvider.propTypes = {
  children: PropTypes.node,
}

export { LeaderboardContext, LeaderboardProvider, actionTypes }
