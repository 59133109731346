import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { useStyles } from '../scramble-leaderboard.style-hook'

const FlagLeaderboardHead = (props) => {
  const { flags, showFavorite, nested } = props
  const styles = useStyles({ showFavorite, nested })
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  return (
    <Box
      display="flex"
      mb={1}
      className={styles.headContainer}
      role="row"
    >
      <div className={`${styles.rank} ${styles.labelRank}`} role="columnheader" aria-label="position">POS</div>
      <div className={`${styles.rank} ${styles.labelRank} ${styles.hideMobile}`} role="columnheader" aria-label="move">MOV</div>
      <div className={styles.itemName}>Team</div>
      <div className={`${styles.labelValue} ${styles.hideMobile}`} role="columnheader" aria-label="flag 1 score">{isMobile ? 'FL 1' : 'Flag 1'}</div>
      {flags >= 2 && <div className={`${styles.labelValue} ${styles.hideMobile}`} role="columnheader" aria-label="flag 2 score">{isMobile ? 'FL 2' : 'Flag 2'}</div>}
      {flags >= 3 && <div className={`${styles.labelValue} ${styles.hideMobile}`} role="columnheader" aria-label="flag 3 score">{isMobile ? 'FL 3' : 'Flag 3'}</div>}
      <div className={styles.labelValue} aria-label="total score" role="columnheader">
        <div aria-hidden="true">Tot<span className={styles.hideMobile}>al</span></div>
      </div>
    </Box>
  )
}

FlagLeaderboardHead.propTypes = {
  flags: PropTypes.number,
  showFavorite: PropTypes.bool,
  nested: PropTypes.bool,
}

FlagLeaderboardHead.defaultProps = {}

export default FlagLeaderboardHead
