import { makeStyles, styled } from '@material-ui/core/styles'

export const CoachName = styled('div')(({ theme, coach }) => ({
  marginLeft: coach.profilePhoto ? 0 : '130px',
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  alignItems: 'left',
  justifyContent: 'center',
  paddingRight: 0,
  paddingLeft: theme.spacing(2),
  minWidth: 120,
  [theme.breakpoints.down('md')]: {
    marginLeft: coach.profilePhoto ? 0 : '55px',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: coach.profilePhoto ? 0 : '40px',
  },
  [theme.breakpoints.down('xs')]: {
    minWidth: 100,
  },
}))

export const useStyles = makeStyles(theme => ({
  myLeaderboard: {
    '&>div:nth-child(1)>div': {
      borderRadius: '8px 8px 0 0',
      marginBottom: 0,
    },
    '&>div:nth-child(2)': {
      borderRadius: '0 0 8px 8px',
    },
  },
  wrapper: {
    '&>div:nth-child(2)': {
      borderRadius: '8px 8px 0 0',
      marginBottom: 0,
    },
    '&>div:nth-child(3)': {
      borderRadius: '0 0 8px 8px',
    },
  },
  outer: {
    borderRadius: '8px',
    background: theme.palette.common.white,
    marginBottom: theme.spacing(1),
    boxShadow: theme.palette.shadow.cardBoxShadow,
  },
  inner: {
    backgroundColor: theme.palette.blue.light25,
    paddingTop: theme.spacing(2),
    borderRadius: '0 0 8px 8px',
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  item: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.light,
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  itemTeam20: {
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
  },
  coachItem: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '60px',
    color: theme.palette.common.black,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  bordered: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 4,
  },
  topRounding: {
    borderRadius: '8px',
  },
  rank: {
    display: 'flex',
    width: theme.spacing(4),
    paddingRight: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.light,
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(8),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  move: {
    display: 'flex',
    width: theme.spacing(4),
    paddingRight: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.light,
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(8),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  moveUp: {
    color: '#007C05',
  },
  moveDown: {
    color: '#CF0000',
  },
  moveArrow: {
    marginRight: '-4px',
  },
  moveUpArrow: {
    transform: 'rotate(180deg)',
  },
  itemName: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
    paddingRight: 0,
    paddingLeft: theme.spacing(2),
    minWidth: 120,
    [theme.breakpoints.down('xs')]: {
      minWidth: 100,
    },
    [theme.breakpoints.up('sm')]: {
      height: '4em',
    },
    [theme.breakpoints.up('lg')]: {
      height: '5em',
    },
  },
  itemStreamUrl: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: theme.spacing(5),
  },
  liveStreamIcon: {
    background: 'url(/images/liveStreamIcon.svg)',
    width: 34,
    height: 24,
    '&:hover': {
      background: 'url(/images/liveStreamIconHover.svg)',
    },
  },
  clickHover: {
    cursor: 'pointer',
  },
  caption: {
    color: theme.palette.grey.dark,
    textAlign: 'left',
    paddingLeft: '15px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '5px',
    },
  },
  firstName: {
    color: theme.palette.grey.dark,
    fontSize: 12,
    lineHeight: 1,
  },
  lastName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
    color: theme.palette.secondary.main,
  },
  itemValue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(6),
    height: theme.spacing(5),
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(10),
    },
  },
  itemFlag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '3em',
    height: '3em',
    borderRadius: '50%',
    border: `2px solid ${theme.palette.divider}`,
    overflow: 'hidden',
    boxShadow: '0px 5px 5px rgba(0,0,0,0.3)',
    [theme.breakpoints.down('md')]: {
      width: '2em',
      height: '2em',
      border: `1px solid ${theme.palette.divider}`,
      marginLeft: '2px',
      marginRight: '2px',
    },
    '&.hide': {
      opacity: 0,
    },
  },
  roundImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '3em',
    height: '3em',
    borderRadius: '50%',
    border: `2px solid ${theme.palette.divider}`,
    overflow: 'hidden',
    marginLeft: '50px',
    boxShadow: '0 5px 5px rgba(0, 0, 0, 0.3)',
    [theme.breakpoints.down('md')]: {
      width: '2em',
      height: '2em',
      border: `1px solid ${theme.palette.divider}`,
      marginLeft: '25px',
      marginRight: '2px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '12px',
    },
  },
  coachAvatar: {
    height: '38px',
    width: '38px',
    [theme.breakpoints.down('md')]: {
      height: '26px',
      width: '26px',
    },
  },
  itemScore: {
    [theme.breakpoints.only('xs')]: {
      borderRight: 'none !important',
    },
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    right: 0,
    [theme.breakpoints.up('md')]: {
      right: theme.spacing(3),
    },
  },
  labelRank: {
    textTransform: 'uppercase',
    color: theme.palette.secondary.contrastText,
    fontSize: '14px',
    paddingRight: 0,
    paddingLeft: 0,
    [theme.breakpoints.only('sm')]: {
      fontSize: '11px',
    },
  },
  labelValue: {
    display: 'flex',
    width: theme.spacing(6),
    height: theme.spacing(5),
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 1.5,
    fontSize: '14px',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(10),
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '11px',
    },
  },
  hideMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  teeTime: {
    textAlign: 'center',
    lineHeight: 1,
    '& div': {
      paddingTop: 10,
    },
    '& span': {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 10,
      color: theme.palette.grey.dark,
    },
  },
  descriptionTitle: {
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
    letterSpacing: 1.25,
    color: theme.palette.grey[700],
  },
  fullLeaderBoardContainer: {
    marginTop: theme.spacing(13),
  },
  coachPgaLogo: {
    paddingRight: 0,
    paddingLeft: '15px',
    maxWidth: '112px',
    minWidth: '80px',
    [theme.breakpoints.down('md')]: {
      minWidth: '100px',
      maxWidth: '100px',
      '& .MuiTypography-caption': {
        fontSize: '10px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80px',
      minWidth: '80px',
      paddingLeft: '5px',
      justifyContent: 'center',
      '& img': {
        paddingBottom: theme.spacing(1),
      },
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '65px',
      minWidth: '65px',
    },
  },
  nativeDescription: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    paddingRight: 0,
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: theme.spacing(30),
    },
  },
  actionButton: {
    margin: '0 8px 18px 0',
    padding: '8px',
    fontSize: '12px',
    borderRadius: '5px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      margin: '8px',
    },
  },
  carrotCta: {
    margin: '0 15px 16px 125px',
    [theme.breakpoints.down('md')]: {
      margin: '0 15px 16px 50px',
    },
  },
  team20triangle: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    backgroundColor: props => (props.team20Data && props.team20Data.backgroundColor) || '#EEEEEE',
    width: '250px',
    height: '100px',
    transform: 'rotate(-22deg) translate(50px, 110px)',

    [theme.breakpoints.up('sm')]: {
      transform: 'rotate(-22deg) translate(50px, 98px)',
    },

    [theme.breakpoints.up('md')]: {
      transform: 'rotate(-22deg) translate(50px, 86px)',
    },
  },
  team20logo: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    paddingRight: '4px',
    paddingBottom: '5px',
    '& img': {
      display: 'block',
      width: props => (props.team20Data && props.team20Data.logoWidthXs) || '36px',
    },

    [theme.breakpoints.up('sm')]: {
      paddingRight: '6px',
      paddingBottom: '6px',
      '& img': {
        width: props => (props.team20Data && props.team20Data.logoWidthSm) || '45px',
      },
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: '7px',
      paddingBottom: '8px',
      '& img': {
        width: props => (props.team20Data && props.team20Data.logoWidth) || '54px',
      },
    },
  },
  disclaimer: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    color: '#9E9E9E',
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    color: theme.palette.secondary.light,
  },
  liveLine: {
    display: 'flex',
    alignItems: 'center',
  },
  formInputs: {
    marginLeft: '0.5625rem',
    whiteSpace: 'nowrap',
    lineHeight: '16px',
    ...theme.typography.formInputs,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headlineWrapper: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2),
    },
  },
  headline: {
    marginBottom: theme.spacing(1),
  },
  chevron: {
    color: theme.palette.grey.main,
    transitionProperty: 'transform',
    transitionDuration: theme.transitions.duration.shortest,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    '&.expanded': {
      transform: 'rotate(-180deg)',
    },
  },
}))
