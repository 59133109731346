import PropTypes from 'prop-types'
import Link from '@components/link'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { CtaText, CtaArrow } from '@components/atoms'
import { useStyles } from './hero.styles'

const variants = {
  default: {
    height: [320, 400],
    py: [6, 8],
    innerWidth: ['100%', 520],
    headlineSize: 'h3',
  },
  overlap: {
    height: [320, 400],
    py: [4, 6],
    pb: 12,
    innerWidth: ['100%', 520],
    headlineSize: 'h3',
  },
}

const Hero = (props) => {
  const {
    background,
    gradient,
    heroText,
    variant,
    cta,
    link,
    className,
  } = props

  const styles = useStyles({ background, gradient })

  return (
    <Box
      height={variants[variant].height}
      pt={variants[variant].py}
      pb={variants[variant].pb || variants[variant].py}
      className={`${className} ${styles.root}`}
    >
      <Container>
        {link ? (
          <Box width={variants[variant].innerWidth}>
            <Link {...link} headline={heroText}>
              <Typography variant={variants[variant].headlineSize} component="h1">{heroText}</Typography>
              <CtaText mt={[2]}>
                <span className="hero--cta">
                  {cta || 'Read Story'} <CtaArrow color="white" />
                </span>
              </CtaText>
            </Link>
          </Box>
        ) : (
          <Box width={variants[variant].innerWidth}>
            <Typography variant={variants[variant].headlineSize} component="h1">{heroText}</Typography>
          </Box>
        )}
      </Container>
    </Box>
  )
}

Hero.propTypes = {
  cta: PropTypes.string,
  heroText: PropTypes.string,
  background: PropTypes.shape({
    url: PropTypes.string,
  }),
  link: PropTypes.shape({
    __typename: PropTypes.string,
    slug: PropTypes.string,
    sys: PropTypes.shape({
      id: PropTypes.string,
      publishedAt: PropTypes.string,
    }),
  }),
  variant: PropTypes.string,
}

Hero.defaultProps = {
  variant: 'default',
  cta: 'Read Story',
}

export default Hero
