import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'
import Close from '@material-ui/icons/Close'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { CloseButton } from '@components/leaderboard/leaderboard.styles'
import Box from '@material-ui/core/Box'
import FlagInnerItem from './flag-inner-item'
import {
  useStyles,
  RoundTab,
  RoundTabs,
} from '../scramble-leaderboard.style-hook'

const FlagInnerWrapper = (props) => {
  const {
    handleToggle,
    matches,
    currentFlag,
  } = props

  const styles = useStyles()

  // FIXME: If there are no matches, we can't display matches
  if (matches.length < 1) return <div>No Matches Available</div>

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  const [selectedFlag, setSelectedFlag] = useState(currentFlag - 1)

  const handleFlagChange = (event, newValue) => {
    setSelectedFlag(newValue)
  }

  return (
    <div style={{ backgroundColor: 'inherit' }}>
      <div className={styles.tabsCloseRow}>
        <CloseButton className={styles.closeButton} onClick={handleToggle}><Close /></CloseButton>
        <RoundTabs value={selectedFlag} onChange={handleFlagChange}>
          <RoundTab
            label={isMobile ? 'FL 1' : 'Flag 1'}
          />
          {currentFlag > 1 && (
            <RoundTab
              label={isMobile ? 'FL 2' : 'Flag 2'}
            />
          )}
          {currentFlag > 2 && (
            <RoundTab
              label={isMobile ? 'FL 3' : 'Flag 3'}
            />
          )}
        </RoundTabs>
      </div>

      <div
        className={styles.flagHeader}
        role="row"
      >
        <div className={styles.teamColumnHeader} role="columnheader">Match</div>
        <div className={`${styles.flagColumnHeader} ${styles.hideMobile}`} role="columnheader">Status</div>
        <div className={`${styles.flagColumnHeader} ${styles.hideMobile}`} role="columnheader">Thru</div>
        <div className={styles.flagColumnHeader} role="columnheader">Points</div>
      </div>
      <Box className={styles.teamsContainer}>
        {matches[selectedFlag].map((match) => {
          return (
            <FlagInnerItem
              key={`${match.teamState} ${match.matchId}`}
              {...match}
              isMobile={isMobile}
            />
          )
        })}
      </Box>
    </div>
  )
}

FlagInnerWrapper.propTypes = {
  handleToggle: PropTypes.func,
  matches: PropTypes.array,
  currentFlag: PropTypes.number,
}

FlagInnerWrapper.defaultProps = {}

export default FlagInnerWrapper
