import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { DateRange } from '@components/atoms'
import {
  IconDateFilledOutlined,
  IconFlagFilledOutlined,
  IconMarkerOutlinePlusFill,
  IconArrowExternal,
} from '@components/atoms/icons'
import IconTicket from '@components/atoms/icons/icon-ticket'
import useStyles from './card.styles'

const CardEventFields = (props) => {
  const {
    startDate,
    endDate,
    location,
    course,
    ticketCta,
    ticketLink,
    showTicketLink,
  } = props

  const s = useStyles()

  return (
    <div>
      {course
        ? (
          <div className={s.field}>
            <IconFlagFilledOutlined className={s.icon} />
            <Typography
              className={s.fieldText}
            >
              {course}
            </Typography>
          </div>
        ) : null}
      <div className={s.field}>
        <IconMarkerOutlinePlusFill className={s.icon} />
        <Typography
          className={s.fieldText}
        >
          {location}
        </Typography>
      </div>
      {startDate
        ? (
          <div className={s.field}>
            <IconDateFilledOutlined className={s.icon} />
            <Typography
              className={s.fieldText}
            >
              <DateRange startDate={startDate} endDate={endDate} />
            </Typography>
          </div>
        )
        : null}
      {showTicketLink && ticketLink && (
        <a className={s.field} href={ticketLink} target="_blank" rel="noopener noreferrer">
          <IconTicket className={s.iconTicket} />
          <Typography className={s.ticketLink} variant="caption">
            {ticketCta || 'Get Tickets'}
          </Typography>
          <IconArrowExternal className={s.ticktLinkArrow} viewBox="0 0 24 11" />
        </a>
      )}
    </div>
  )
}

CardEventFields.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  location: PropTypes.string,
  course: PropTypes.string,
  ticketCta: PropTypes.string,
  ticketLink: PropTypes.string,
  showTicketLink: PropTypes.bool,
}

export default CardEventFields
