import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from '@components/error'
import defaults from '@config/defaults'
import ErrorBoundary from '@components/error/boundary'
import FlagLeaderboard from './flag-leaderboard'

const FLAG_QUERY = gql`
query TourDataFeedTournament($id: String!, $feedId: String!) {
  tournament(tournamentId: $id, feedId: $feedId) {
      name
      tournamentId
      startDate
      feedId
      currentRound
      currentRoundState
      updatedAt
      city
      format
      flagTeamLeaderboards {
              teamName
              teamId
              move
              currentFlag
              totalPoints
              teamPosition
              flags {
                  flagName
                  points
                  players {
                      matchId
                      teamId
                      playerId
                      name
                      status
                      points
                      thru
                      flagName
                      courseName
                      holes {
                          hole
                          score
                      }
                  }
              }
          }
      }
  }
`

export default function FlagLeaderboardWithData(props) {
  const pollInterval = process.browser ? defaults.leaderboardPollInterval : null
  const fetchPolicy = process.browser ? 'cache-and-network' : 'cache-first'

  return (
    <Query
      query={FLAG_QUERY}
      variables={props}
      pollInterval={pollInterval}
      fetchPolicy={fetchPolicy}
      context={{ clientName: 'pga-datafeed' }}
    >
      {({
        loading, error, data,
      }) => {
        const response = data && data.tournament

        if (error) return <Error error={error} />
        if (loading && !response) return <LinearProgress color="secondary" />
        return (
          <ErrorBoundary>
            <FlagLeaderboard {...props} {...response} />
          </ErrorBoundary>
        )
      }}
    </Query>
  )
}

FlagLeaderboardWithData.propTypes = {}
