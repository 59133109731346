/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import algoliasearch from 'algoliasearch'
import NoSsr from '@material-ui/core/NoSsr'
import ErrorBoundary from '@components/error/boundary'
import LeaderboardCutLine from '@components/leaderboard-cut-line'
import CoachAdvertisement from '@components/leaderboard/CoachAdvertisement'
import { getClosestCoachByIp } from '@components/leaderboard/utils'
import { PlayoffButton } from '@components/leaderboard/leaderboard.style-hook'
import { LeaderboardContext, actionTypes } from './team-leaderboard.provider'
import TeamLeaderboardHead from './team-leaderboard-head'
import TeamStrokeLeaderboardItem from './team-stroke-leaderboard-item'
import { useStyles } from '../leaderboard-scramble/scramble-leaderboard.style-hook'

const client = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY)
const algoliaIndex = client.initIndex(process.env.ALGOLIA_COACH_INDEX_NAME)

const TeamLeaderboard = React.memo((props) => {
  const {
    id,
    feedId,
    bordered,
    currentRound,
    currentRoundState,
    rounds = 4,
    enableStatus,
    liveStreamUrl,
    nativeAdTitle,
    nativeAdDescription,
    nativeAdCta,
    nativeAdActive,
    cutPlaces,
    cutRound,
    cutScore,
    fullPage,
    limit,
    sorted,
    nested,
    totalRounds,
    cutLineText,
    cutLineTextOverride,
    cutPlacesOverride,
  } = props

  const tournamentRounds = totalRounds || rounds
  const isTournamentComplete = Number(currentRound) === tournamentRounds && currentRoundState === 'Completed'
  const playoffStarted = isTournamentComplete && sorted[0]?.teamPosition === 'T1'
  const playoffCompleted = isTournamentComplete && (sorted[0]?.tournamentRelativePar === sorted[1]?.tournamentRelativePar) && Number(sorted[0]?.currentPosition) === 1
  const showPlayoffButton = (playoffStarted || playoffCompleted) && !nested
  const playoffText = playoffCompleted ? 'Playoff: Complete' : 'Playoff: In Progress'
  const leaderboardCutPlaces = cutPlacesOverride || cutPlaces

  const styles = useStyles({ nested })

  const [nearestCoach, setNearestCoach] = useState(null)
  const [loadingCoach, setLoadingCoach] = useState(false)

  useEffect(() => {
    const getCoach = async () => {
      setLoadingCoach(true)
      const coach = await getClosestCoachByIp(algoliaIndex)
      setLoadingCoach(false)
      if (coach) {
        setNearestCoach(coach)
      }
    }
    getCoach()
  }, [])

  const { state, dispatch } = useContext(LeaderboardContext)
  const { teams: teamsSaved } = state[id] || []

  const showPlacesDict = {
    3: 3,
    5: 5,
    10: 10,
    20: 20,
    'Cut only': cutPlaces,
    'All players': null,
    null: 5, // default places if no leaderboardPlaces value provided by Contentful
  }

  const limitValue = showPlacesDict[limit]
  const showFavorite = fullPage && !nested

  const show = limitValue ? sorted.slice(0, limitValue) : sorted

  const addTeamFav = (team) => {
    dispatch({ type: actionTypes.ADD_TEAM, payload: { tournamentId: id, team } })
  }

  const removeTeamFav = (team) => {
    dispatch({ type: actionTypes.REMOVE_TEAM, payload: { tournamentId: id, team } })
  }

  return (
    <Container className={styles.leaderboardWrapper} role="table">
      {showPlayoffButton && <PlayoffButton complete={{ playoff: playoffCompleted }}>{playoffText}</PlayoffButton>}
      <TeamLeaderboardHead rounds={rounds} showFavorite={showFavorite} nested={nested} />
      {show.map((team, i) => (
        <ErrorBoundary key={team.teamId}>
          {i === 5 && nativeAdActive && nearestCoach
            ? (
              <NoSsr>
                <CoachAdvertisement
                  isLoading={loadingCoach}
                  coach={nearestCoach}
                  nativeAdTitle={nativeAdTitle}
                  nativeAdDescription={nativeAdDescription}
                  nativeAdCta={nativeAdCta}
                />
              </NoSsr>
            ) : null}
          {leaderboardCutPlaces && i === leaderboardCutPlaces ? (
            <LeaderboardCutLine
              cutScore={cutScore}
              cutRound={cutRound}
              currentRound={currentRound}
              currentRoundState={currentRoundState}
              cutLineText={cutLineText}
              cutLineTextOverride={cutLineTextOverride}
            />
          ) : null}
          <TeamStrokeLeaderboardItem
            bordered={bordered}
            {...team}
            tournamentId={id}
            feedId={feedId}
            enableStatus={enableStatus}
            totalRounds={rounds}
            liveStreamUrl={liveStreamUrl}
            addItem={addTeamFav}
            removeItem={removeTeamFav}
            saved={teamsSaved && teamsSaved.filter(
              el => el.teamId === team.teamId,
            ).length > 0}
            showFavorite={showFavorite}
            winner={playoffCompleted && i === 0}
          />
        </ErrorBoundary>
      ))}
    </Container>
  )
})

TeamLeaderboard.propTypes = {
  bordered: PropTypes.bool,
  feedId: PropTypes.string,
  id: PropTypes.string,
  rounds: PropTypes.number,
  liveStreamUrl: PropTypes.string,
  enableStatus: PropTypes.bool,
  nativeAdTitle: PropTypes.string,
  nativeAdDescription: PropTypes.string,
  nativeAdCta: PropTypes.string,
  nativeAdActive: PropTypes.bool,
  currentRound: PropTypes.string,
  currentRoundState: PropTypes.string,
  cutPlaces: PropTypes.number,
  cutRound: PropTypes.number,
  cutScore: PropTypes.number,
  fullPage: PropTypes.bool,
  limit: PropTypes.number,
  sorted: PropTypes.array,
  nested: PropTypes.bool,
  totalRounds: PropTypes.number,
  cutLineText: PropTypes.string,
  cutLineTextOverride: PropTypes.string,
  cutPlacesOverride: PropTypes.number,
}

TeamLeaderboard.defaultProps = {
}

export default TeamLeaderboard
