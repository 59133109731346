import PropTypes from 'prop-types'
import BrandedWatchLiveButton from './watch-live-button-brand'

const WatchLiveButton = (props) => {
  const {
    href,
    watchLiveVendor,
  } = props

  switch (watchLiveVendor) {
  case 'branded':
  default:
    return (
      <BrandedWatchLiveButton href={href} />
    )
  }
}

WatchLiveButton.propTypes = {
  href: PropTypes.string.isRequired,
  watchLiveVendor: PropTypes.string,
}

WatchLiveButton.defaultProps = {
  watchLiveVendor: 'branded',
}

export default WatchLiveButton
